import React from 'react';
import update from 'immutability-helper';
import SignatureCanvas from 'react-signature-canvas';
import Checkbox from 'material-ui/Checkbox';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import Snackbar from 'material-ui/Snackbar';
import Paper from 'material-ui/Paper';
import {CardActions} from 'material-ui/Card'
import Dialog from 'material-ui/Dialog';

import {getBase64, getImageWithCrop, sendRequest, triggerEvent, initCameraPhoto} from '../helpers/global.js';
import Storage from '../helpers/Storage';

import VerificationCodeView from './LoginView/VerificationCodeView';
import PasswordInput from './input/PasswordInput';

let USER_KEYS = ['username', 'email', 'name', 'phone', 'email_notify', 'sms_auth', 'avatar_image', 'sign_url'];

export default class AccountDataView extends React.Component {

  constructor(props) {
    super(props);

    let user = Storage.getData('user');
    let data = {};
    USER_KEYS.forEach(key => {
      data[key] = user[key];
    })
    this.state = {
      data,
      inputErrors: {},
      showAlert: false,
      sms_auth: {
        show: false,
        phone: null,
        token: null,
        sendTime: null,
      },
      showClear: false,
    };
  }

  componentDidMount = () => {
  }

  onClearClick = () => {
    Storage.clearAllData(() => {
      window.location.reload();
    });
  }

  onSubmit = (e) => {
    if (e) e.preventDefault();

    let data = this.state.data;
    if (this.sigCanvas) {
      data.sign = this.sigCanvas.toDataURL();
    }
    let inputErrors = {};

    if (!data.name) {
      inputErrors.name = 'Please enter name';
    }
    /*if (data.sms_auth && !data.phone) {
      inputErrors.phone = 'Please enter phone';
    }*/
    if (data.repeat_password !== data.new_password) {
      inputErrors.repeat_password = 'Passwords do not match';
    }
    
    if (Object.keys(inputErrors).length > 0) {
      this.setState({inputErrors}, () => {
        let firstErrorKey = Object.keys(inputErrors).find(key => !!this[key]);
        this[firstErrorKey].scrollIntoView({
          block: 'center', 
          behavior: 'smooth'
        });
      });
    } else {
      triggerEvent('addLoad');
      sendRequest({
        type: 'PUT',
        method: 'me/settings',
        data,
        success: (data)  => {
          if (data.sms_token) {
            this.setState({
              sms_auth: {
                show: true,
                phone: data.phone,
                token: data.sms_token,
                sendTime: Date.now(),
              },
            });
          } else {
            Storage.setData('user', data);
            this.setState({showAlert: true});
          }
          triggerEvent('removeLoad');
        },
        error: (data) => {
          if (data && data.errors) {
            this.setState({inputErrors: data.errors});
          }
          triggerEvent('removeLoad');
        }
      })
    }
  }

  processPhoto = (key, base64) => {
    getImageWithCrop(base64, null, 768, 0.6, (base64) => {
      this.setState(update(this.state, {
        data: {
          [key]: {$set: base64},
        },
      }))   
    });
  }

  renderPhotoField = (key) => {
    let inputKey = `image-${key}`;
    let value = this.state.data[key];
    return (
      <div style={{padding: '18px 0px 10px'}}>
        {value ? <Paper zDepth={1} style={{fontSize: 0, marginBottom: 12}}>
          <img src={value} style={{maxWidth: '100%'}} alt=''/>
        </Paper> : null}
        <input 
          type='file'
          onChange={(e) => {
            if (e.target.files && e.target.files.length > 0) {
              let file = e.target.files[0];
              getBase64(file, (base64) => {
                this.processPhoto(key, base64);
              });
            }
          }}
          ref={input => this[inputKey] = input}
          accept='image/*'
          style={{display: 'none'}}
        />
        <RaisedButton
          label={value ? 'Change photo' : 'Upload photo'}
          onClick={() => {
            if (window.cordova) {
              initCameraPhoto(base64 => this.processPhoto(key, base64));
            } else {
              this[inputKey].click();
            }
          }}
        />
      </div>
    )
  }

  renderPasswordField = (key, title) => {
    return (
      <PasswordInput
        name={key}
        title={title}
        value={this.state.data[key] || ''}
        error={this.state.inputErrors[key] || null}
        onChange={val => {
          this.setState(update(this.state, {
            data: {
              [key]: {$set: val},
            },
            inputErrors: {$unset: [key]},
          }))
        }}
        refInput={input => this[key] = input}
      />
    )
  }

  renderTextField = (key, title, disabled) => {
    return (
      <div key={key} style={{height: 75}}>
        <TextField
          name={key}
          hintText=''
          floatingLabelText={title}
          floatingLabelFixed={true}
          errorText={this.state.inputErrors[key] || null}
          style={{width: '100%'}}
          disabled={!!disabled}
          autoComplete={key}
          value={this.state.data[key] || ''}
          onChange={(e, val) => {
            this.setState(update(this.state, {
              data: {
                [key]: {$set: val},
              },
              inputErrors: {$unset: [key]},
            }))
          }}
          ref={(textField) => {
            if (textField) {
              this[key] = textField.input;
            }
          }} 
        />
      </div>
    )
  }

  renderCheckboxField = (key, title) => {
    return (
      <div key={key} style={{padding: '16px 0px 4px'}}>
        <Checkbox
          label={title}
          checked={!!this.state.data[key]}
          onCheck={() => {
            this.setState(update(this.state, {
              data: {
                [key]: {$set: !this.state.data[key]},
              },
            }))
          }}
        />
      </div>
    )
  }

  renderSignatureInput = () => {
    let width = document.body.clientWidth;
    return (
      <>
        <div style={{border: '1px solid #cccccc', backgroundColor: '#ffffff', fontSize: 0}}>
          {this.state.data.sign_url ? <img src={this.state.data.sign_url} alt='' style={{width: 'calc(100% + 2px)'}}/> :
            <SignatureCanvas
              penColor='#000000'
              canvasProps={{width: (width-50), height: (width-50)/2, className: 'signatureCanvas'}}
              ref={(ref) => this.sigCanvas = ref}
            />
          }
        </div>
        <CardActions style={{textAlign: 'right', padding: '16px 0px'}}>
          <RaisedButton
            label='Clear'
            onClick={() => {
              if (this.sigCanvas) {
                this.sigCanvas.clear();
              } else {
                this.setState(update(this.state, {
                  data: {
                    sign_url: {$set: null},
                  },
                }))
              }
            }}
            style={{margin: 0}}
          />
        </CardActions>
      </>
    )
  }

  render = () => {
    let sms_auth = this.state.sms_auth;
    return (
      <form style={{padding: 25}} onSubmit={e => e.preventDefault()}>

        <h2>Account settings</h2>
        {this.renderTextField('username', 'Username', true)}
        {this.renderTextField('email', 'Email')}
        {this.renderTextField('name', 'Name')}
        {this.renderTextField('phone', 'Phone')}
        {this.renderCheckboxField('email_notify', 'Email notifications')}
        {/*this.renderCheckboxField('sms_auth', '2 Factor Auth (SMS)')*/}
        {this.renderPhotoField('avatar_image')}

        <h2>Change password</h2>
        {this.renderPasswordField('old_password', 'Old Password')}
        {this.renderPasswordField('new_password', 'New Password')}
        {this.renderPasswordField('repeat_password', 'Repeat Password')}

        <h2>Your signature</h2>
        {this.renderSignatureInput()}

        <div className='buttonContainer'>
          <RaisedButton 
            label='Save'
            primary={true}
            fullWidth={true}
            onClick={this.onSubmit}
            style={{marginTop: 16}}
          />
          <FlatButton 
            label='Clear data'
            fullWidth={true}
            onClick={() => this.setState({showClear: true})}
            style={{marginTop: 32, opacity: 0.5}}
          />
        </div>

        <Dialog
          title='Clear Data'
          contentStyle={{width: 'calc(100% - 40px)', maxWidth: 400}}
          actions={[
            <FlatButton
              label='Cancel'
              onClick={() => this.setState({showClear: false})}
            />,
            <RaisedButton
              label='Clear'
              onClick={() => {
                this.setState({showClear: false});
                this.onClearClick();
              }}
            />,
          ]}
          modal={false}
          open={this.state.showClear}
          onRequestClose={() => this.setState({showClear: false})}
        >
          Are you sure want to clear local data?
        </Dialog>

        <VerificationCodeView
          show={sms_auth.show}
          token={sms_auth.token}
          phone={sms_auth.phone}
          sendTime={sms_auth.sendTime}
          method='me/verify_phone'
          onSuccess={(data) => {
            this.setState({sms_auth: {...sms_auth, show: false}});
            Storage.setData('user', data);
            this.setState({showAlert: true});
          }}
          onResend={(data) => {
            this.setState({sms_auth: {...sms_auth, sendTime: Date.now()}})
          }}
          onClose={() => this.setState({sms_auth: {...sms_auth, show: false}})}
        />

        <Snackbar
          open={this.state.showAlert}
          message='Profile updated'
          autoHideDuration={4000}
          onRequestClose={() => this.setState({showAlert: false})}
        />
      </form>
    )
  }

}

import React from 'react';
import {CardTitle} from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';

import {sendRequest, triggerEvent} from '../helpers/global.js';

import '../scss/components/pagination.scss';

export default class ScanInfoView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: false,
    }
  }

  componentDidMount = () => {
    triggerEvent('addLoad');
    sendRequest({
      type: 'GET',
      method: 'assets/scan',
      data: {
        code: this.props.code,
      },
      success: (data) => {
        this.setState({data});
        triggerEvent('removeLoad');
      },
      error: (error) => {
        triggerEvent('removeLoad');
      }
    });
  }

  render = () => {
    let data = this.state.data;
    if (data === false) {
      return null;
    } else if (!data) {
      return <CardTitle title='Asset not found' titleStyle={{padding: 0}}/>
    }
    return (
      <div>
        <CardTitle
          title={`S/N: ${data.serial_number}`}
          subtitle={data.asset_class_name}
          titleStyle={{padding: 0, fontSize: '22px'}}
          subtitleStyle={{fontSize: '18px'}}
        />
        {data.service_history_url ?
          <div style={{padding: '0px 16px 16px'}}>
            <RaisedButton
              label='Open service history'
              onClick={() => window.open(data.service_history_url, '_blank')}
            />
          </div>
        : null}
      </div>
    )
  }

}

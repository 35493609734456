import React from 'react';
import ReactDOM from 'react-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { Provider } from 'react-redux'
import Storage from './helpers/Storage';
import NotificationsController from './helpers/NotificationsController';
import * as serviceWorker from './serviceWorker';
import Zaiko from './Zaiko';
import * as Sentry from '@sentry/browser';

import './scss/index.scss';
import './scss/animations.scss';

Sentry.init({dsn: "https://cda3dc6f55844efcacf4d39fc0dee780@sentry.io/1506893"});

const muiTheme = getMuiTheme({
  palette: {
    primary1Color: '#57d599',
    //primary2Color: '#827ce3',
    //primary3Color: grey400,
    accent1Color: '#827ce3',
    //accent2Color: grey100,
    //accent3Color: grey500,
    //textColor: darkBlack,
  },
  appBar: {
    color: '#ffffff',
    textColor: '#333333',
  },
  tab: {
  },
  tabs: {
    backgroundColor: '#ffffff',
    textColor: '#666666',
    selectedTextColor: '#57d599',
  },
});

const startApp = () => {
  ReactDOM.render(
    <MuiThemeProvider muiTheme={muiTheme}>
      <Provider store={Storage.getStore()}>
        <Zaiko/>
      </Provider>
    </MuiThemeProvider>,
  document.getElementById('root'));
  serviceWorker.register();
};

if(window.cordova) {
  document.addEventListener('deviceready', () => {
    if (window.Keyboard) {
      window.Keyboard.shrinkView(true);
    }
    NotificationsController.init();
    startApp();
  }, false);
} else {
  startApp();
}



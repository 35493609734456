import React from 'react';
import moment from 'moment';
import Storage from '../../helpers/Storage';

import '../../scss/components/SummaryTable.scss';

export default class OrderSummaryView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      order: (Storage.getData('orders') || []).find(t => t.id === parseInt(this.props.id)),
      checklists: Storage.getData('checklists') || [],
      assetClasses: Storage.getData('asset_classes') || [],
      openTime: new Date(),
    }
  }

  componentDidMount = () => {
  }

  render = () => {
    let user = Storage.getData('user') || {};
    let order = this.state.order;
    let startTime = moment(order.started_at);
    let defects = [];
    let serialNumbers = [];
    order.parts.forEach(part => {
      let partAnswers = this.props.answers[part.id] || {};
      return Object.keys(partAnswers).filter(assetId => assetId !== 'complete').forEach(assetId => {
        let serialNumber = partAnswers[assetId].serial_number;
        if (serialNumbers.indexOf(serialNumber) < 0) {
          serialNumbers.push(serialNumber);
        }
        let assetAnswers = partAnswers[assetId].answers;
        part.questions
          .filter(q => q.type === 'checklist')
          .filter(q => (assetAnswers[`${q.id}`] || {}).option === 'failed')
          .forEach(q => defects.push({
            serialNumber,
            question: q,
            answer: assetAnswers[`${q.id}`],
            asset_class_id: part.asset_class_id,
          }));
      })
    });
    return (
      <div className='summaryTable'>
        <h1>Work Order No. {order.identifier}</h1>
        <div className='header'>Service Form</div>
        <div className='content'>
          <div className='dataBlock'>
            <label>Customer:</label>
            <span>{order.customer_name}</span>
          </div>
          <div className='dataBlock'>
            <label>Address:</label>
            <span>{order.customer_address}</span>
          </div>
        </div>
        <div className='content'>
          <div className='dataBlock'>
            <label>Technician:</label>
            <span>{user.name}</span>
          </div>
          <div className='dataBlock'>
            <label>Date:</label>
            <span>{startTime.format('MMMM Do YYYY')}</span>
          </div>
          <div className='dataBlock'>
            <label>Time in:</label>
            <span>{startTime.format('HH:mm')}</span>
          </div>
          <div className='dataBlock'>
            <label>Time out:</label>
            <span>{moment(this.state.openTime).format('HH:mm')}</span>
          </div>
        </div>
        <div className='header'>
          SUMMARY OF WORK COMPLETED
        </div>
        <div className='content'>
          <div className='contentHeader'>
            {user.name} carried out servicing of the following assets:
          </div>
        </div>
        {order.parts.map(part => {
          let checklistsCompleted = (Storage.getData(`checklistsCompleted-${this.state.order.id}-${part.id}`) || []).length;
          let assetClass = this.state.assetClasses.find(ac => ac.id === part.asset_class_id);
          return (
            <div key={part.id} className='content'>
              {checklistsCompleted < part.assets_count ?
                `${checklistsCompleted} out of ${part.assets_count}`
              : checklistsCompleted} x {assetClass.name}
              <br/>
              Servicing scope included: {part.checklists}
            </div>
          )
        })}
        {defects.length > 0 ? <React.Fragment>
          <div className='header'>DEFECT SUMMARY</div>
          {defects.map((defect, index) => {
            let assetClass = this.state.assetClasses.find(ac => ac.id === defect.asset_class_id);
            return (
              <div key={index} className='content defectsContent'>
                <div className='dataBlock'>
                  <label>Serial number:</label>
                  <span>{defect.serialNumber}</span>
                </div>
                {assetClass ?
                  <div className='dataBlock'>
                    <label>Asset type:</label>
                    <span>{assetClass.name}</span>
                  </div>
                : null}
                <div className='failBlock'>
                  {defect.question.title} - Fail
                </div>
                {defect.answer && defect.answer.text ?
                  <div className='dataBlock'>
                    <label>Description:</label>
                    <span>{defect.answer.text}</span>
                  </div>
                : null}
                {defect.answer && defect.answer.photo && defect.answer.photo[0] ?
                  <div className='dataBlock'>
                    <img src={defect.answer.photo[0].image} alt=''/>
                  </div>
                : null}
              </div>
            )
          })}
        </React.Fragment>
        :
        <div className='content'>
          <div className='contentHeader'>
            The Assets were found to be in good working condition at time of inspection.
          </div>
        </div>
        }

      </div>
    )
  }

}

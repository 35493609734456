import React from 'react';
import TextField from 'material-ui/TextField';
import IconButton from 'material-ui/IconButton';
import VisibilityIcon from 'material-ui/svg-icons/action/visibility';
import VisibilityOffIcon from 'material-ui/svg-icons/action/visibility-off';

export default class PasswordInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount = () => {
  }

  render = () => {
    return (
      <div style={{height: 75, position: 'relative'}}>
        <TextField
          inputStyle={{paddingRight: 50}}
          name={this.props.name}
          hintText=''
          floatingLabelText={this.props.title}
          floatingLabelFixed={true}
          errorText={this.props.error || null}
          type={this.state.show ? 'text' : 'password'}
          style={{width: '100%'}}
          disabled={!!this.props.disabled}
          autoComplete={this.props.name}
          value={this.props.value || ''}
          onChange={(e, val) => this.props.onChange(val)}
          ref={(textField) => {
            if (textField) {
              this.props.refInput(textField.input)
            }
          }}
        />
        <IconButton
          onClick={() => this.setState({show: !this.state.show})}
          style={{position: 'absolute', right: 0, top: 15}}
        >
          {this.state.show ? <VisibilityIcon/> : <VisibilityOffIcon/>}
        </IconButton>
      </div>
    )
  }

}

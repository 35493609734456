import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {List} from 'material-ui/List';
import Subheader from 'material-ui/Subheader';
import {Card, CardHeader} from 'material-ui/Card';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import PlayIcon from 'material-ui/svg-icons/av/play-arrow';
import CheckIcon from 'material-ui/svg-icons/navigation/check';

import {sendRequest, triggerEvent, eventOn, eventOff} from '../helpers/global.js';
import Storage from '../helpers/Storage';

import '../scss/components/pullRefresh.scss';

const styles = {
  startButton: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    right: 10,
    boxShadow: 'none',
  },
  completeButton: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    right: 10,
    border: '1px solid #57d599',
    display: 'inline-block',
    width: 40,
    height: 40,
    borderRadius: '50%',
    boxSizing: 'border-box',
    textAlign: 'center',
  },
  completeIcon: {
    height: 40,
    color: '#57d599',
  },
  refreshIcon: {
    height: 34,
    width: 34,
    color: '#57d599',
  },
};

const mapStoreToProps = (store) => {
  return {
    orders: store.data.orders,
  }
};

class OrderListView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      orders: Storage.getData('orders') || [],
    }
  }

  componentDidMount = () => {
    let user = Storage.getData('user');
    if (user.sign_url) {
      this.requestOrders();
    } else {
      triggerEvent('goPage', ['settings']);
      triggerEvent('notification', [{title: 'Signature not set', body: 'Please submit your signature'}]);
    }

    eventOn('refreshOrders', this.requestOrders);
  }

  componentWillUnmount = () => {
    eventOff('refreshOrders', this.requestOrders);
  }

  componentWillReceiveProps = (props) => {
    if (props.orders && props.orders !== this.state.orders) {
      this.setState({orders: props.orders});
    }
  }

  requestOrders = () => {
    triggerEvent('addLoad');
    sendRequest({
      type: 'GET',
      method: 'orders',
      success: (data) => {
        Storage.setData('orders', data);
        triggerEvent('removeLoad');
      },
      error: (error) => {
        triggerEvent('removeLoad');
      }
    });
  }

  renderOrder = (order, index) => {
    let complete = ['finished', 'signed'].indexOf(order.status) > -1;
    let checklistsCompleted = 0;
    let checklistsCount = 0;
    order.parts.forEach(part => {
      checklistsCompleted += (Storage.getData(`checklistsCompleted-${order.id}-${part.id}`) || []).length;
      checklistsCount += part.assets_count;
    });
    return (
      <Card
        key={order.id}
        style={{
          margin: `${index > 0 ? 12 : 0}px 12px 4px`,
          borderRadius: 4,
          boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
          position: 'relative',
        }}
        onClick={() => {
          if (complete) {
            if (order.status === 'finished') {
              triggerEvent('goPage', ['sign_order', order.id]);
            } else {
              triggerEvent('goPage', ['sign_order', order.id]);
            }
          } else {
            triggerEvent('goPage', ['order', order.id]);
          }
        }}
      >
        <CardHeader
          title={order.name}
          subtitle={complete
            ? order.status === 'finished' ? 'Complete' : 'Signed'
            : `${checklistsCompleted} / ${checklistsCount}`}
        />
        {complete ?
          <div style={styles.completeButton}>
            <CheckIcon style={styles.completeIcon}/>
          </div>
          :
          <FloatingActionButton mini={true} style={styles.startButton}>
            <PlayIcon/>
          </FloatingActionButton>
        }
      </Card>
    )
  }

  renderPlaceholder = () => {
    return (
      <div style={{textAlign: 'center'}}>
        <img src='./no-orders.png' alt='' style={{
          width: 240,
          margin: '60px 0px 30px',
        }}/>
        <div style={{
          fontWeight: 400,
          fontSize: '18px',
          color: 'rgba(0, 0, 0, 0.54)',
        }}>You don't have any work orders yet</div>
      </div>
    )
  }

  render = () => {
    let groupedOrders = {};
    this.state.orders.forEach(order => {
      if (!(order.scheduled_at in groupedOrders)) {
        groupedOrders[order.scheduled_at] = [];
      }
      groupedOrders[order.scheduled_at].push(order);
    });
    return (
      <div style={{minHeight: 'calc(100vh - 64px)'}}>
        {Object.keys(groupedOrders).length === 0 ? this.renderPlaceholder() : 
          Object.keys(groupedOrders).sort().map(date => 
            <List key={date}>
              <Subheader style={{fontSize: '16px'}}>
                {moment(date).format('MMMM Do YYYY')}
              </Subheader>
              {groupedOrders[date].map(this.renderOrder)}
            </List>
          )
        }
      </div>
    );
  }

}

export default connect(mapStoreToProps)(OrderListView)

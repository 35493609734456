import React from 'react';

import {CardActions} from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';

const styles = {
  label_header: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '14px',
    fontWeight: 500,
    paddingLeft: 16,
    paddingTop: 12,
  },
  label_value: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '15px',
    fontWeight: 500,
    paddingLeft: 16,
  },
};

export default class OrderStartView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      order: props.order,
    }
  }

  render = () => {
    let order = this.state.order || {};
    let date = new Date();
    let scheduled = new Date(order.scheduled_at).getTime() + date.getTimezoneOffset()*60*1000;
    let dateState = 0;
    if (date - scheduled < 0) {
      dateState = -1;
    } else if (date - scheduled > 1000*60*60*24) {
      dateState = 1;
    }
    return (
      <React.Fragment>
        <div style={styles.label_header}>Customer:</div>
        <div style={styles.label_value}>{order.customer_name}</div>
        <div style={styles.label_header}>Address:</div>
        <div style={styles.label_value}>{order.customer_address}</div>
        {order.remarks ?
          <React.Fragment>
            <div style={styles.label_header}>Special instructions:</div>
            <div style={styles.label_value}>{order.remarks}</div>
          </React.Fragment>
        : null}
        <CardActions style={{textAlign: 'center', padding: '32px 16px 8px'}}>
          <RaisedButton
            label={dateState < 0 ? 'Upcoming' : 'Start Now'}
            fullWidth={true}
            disabled={dateState < 0}
            onClick={() => this.props.onStart()}
            primary={true}
          />
        </CardActions>
      </React.Fragment>
    )
  }

}

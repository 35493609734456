import React from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

const styles = {
  loadBackground: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: 10000,
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
  },
  loadIndicator: {
    left: 'calc(50% - 35px)',
    top: 'calc(40% - 35px)',
  },
}

export default class LoadIndicator extends React.Component {

  render = () => {
    return (
      <ReactCSSTransitionGroup
        transitionName='fade'
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        {this.props.show ? 
          <div style={styles.loadBackground}>
            <CircularProgress size={70} thickness={6} style={styles.loadIndicator}/>
          </div>
        : null}
      </ReactCSSTransitionGroup>
    );
  }

}

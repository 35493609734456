import React from 'react';
import IconButton from 'material-ui/IconButton';
import Tooltip from 'material-ui/internal/Tooltip';

const styles = {
  button: {
    color: '#666666',
    fontSize: '18px',
    lineHeight: '24px',
    border: '1px solid #666666',
    width: 24,
    borderRadius: '50%',
  },
  tooltip: {
    width: 300,
    padding: '6px 10px',
    borderRadius: 2,
    color: '#ffffff',
    fontSize: '14px',
    lineHeight: 'normal',
    textAlign: 'justify',
  },
};

export default class QuestionTooltip extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    }
  }

  componentDidMount = () => {
  }

  render = () => {
    return (
      <div>
        <IconButton
          touch={true}
          style={{position: 'absolute', right: 0, top: 0}}
          onClick={() => this.setState({show: !this.state.show})}
          iconStyle={styles.button}
        >
          <div>?</div>
        </IconButton>
          <Tooltip
          label={<div style={{whiteSpace: 'normal'}} dangerouslySetInnerHTML={{__html: this.props.text || ''}}/>}
          style={styles.tooltip}
          touch={true}
          show={this.state.show}
          horizontalPosition='left'
          verticalPosition='bottom'
        />
      </div>
    )
  }
}
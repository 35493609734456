import React from 'react';
import update from 'immutability-helper';

import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import Paper from 'material-ui/Paper';
import { CardActions, CardTitle } from 'material-ui';

import {getBase64, getImageWithCrop, initCameraPhoto, triggerEvent} from '../helpers/global.js';
import Storage from '../helpers/Storage';

export default class SpecifyAssetView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      assetClasses: Storage.getData('asset_classes') || [],
    };
  }

  componentDidMount = () => {
  }

  componentWillReceiveProps = (props) => {
    if (props.show && !this.props.show) {
      this.setState({data: {}});
    }
  }

  onClose = () => {
    this.props.onClose();
  }

  onSubmit = () => {
    let data = this.state.data;
    let assetData = {
      photo: data.photo,
      model: data.model,
      brand: data.brand,
      location: data.location,
      asset_class_id: this.props.assetClassId,
    };
    triggerEvent('addLoad');
    Storage.requestAnswerData('added_numbers', val => {
      let addedNumbers = val || {};
      let customerNumbers = addedNumbers[this.props.order.customer_id] || {};
      customerNumbers[this.props.serialNumber] = assetData;
      addedNumbers[this.props.order.customer_id] = customerNumbers;
      Storage.storeAnswerData('added_numbers', addedNumbers);

      triggerEvent('removeLoad');
      this.props.onAdd();
    });
  }

  processPhoto = (key, base64) => {
    getImageWithCrop(base64, null, 768, 0.6, (base64) => {
      this.setState(update(this.state, {
        data: {
          [key]: {$set: base64},
        },
      }))
    });
  }

  renderPhotoField = (key) => {
    let value = this.state.data[key] || null;
    return (
      <div key={key}>
        {value ? <Paper zDepth={1} style={{fontSize: 0, marginBottom: 12, maxWidth: 400}}>
          <img src={value} style={{maxWidth: '100%'}} alt=''/>
        </Paper> : null}
        <input 
          type='file'
          onChange={(e) => {
            if (e.target.files && e.target.files.length > 0) {
              let file = e.target.files[0];
              getBase64(file, base64 => this.processPhoto(key, base64))
            }
          }}
          ref={input => this[key] = input}
          accept='image/*'
          style={{display: 'none'}}
        />
        <RaisedButton
          label={value ? 'Change photo' : 'Upload photo'}
          onClick={() => {
            if (window.cordova) {
              initCameraPhoto(base64 => this.processPhoto(key, base64));
            } else {
              this[key].click();
            }
          }}
        />
      </div>
    )
  }

  renderTextField = (key, title) => {
    return (
      <div key={key} style={{height: 75,}}>
        <TextField
          name={key}
          hintText=''
          floatingLabelText={title}
          floatingLabelFixed={true}
          style={{width: '100%'}}
          value={this.state.data[key] || ''}
          onChange={(e, val) => {
            this.setState(update(this.state, {
              data: {
                [key]: {$set: val},
              },
            }))
          }}
        />
      </div>
    )
  }

  renderSelectField = (key, title, options) => {
    return (
      <SelectField
        floatingLabelText={title}
        style={{marginBottom: 16}}
        value={this.state.data[key] || null}
        fullWidth={true}
        onChange={(e, index, val) => {
          this.setState(update(this.state, {
            data: {
              [key]: {$set: val},
            },
          }))
        }}
      >
        {(options || []).map(item => 
          <MenuItem key={item.id} value={item.id} primaryText={item.name}/>
        )}
      </SelectField>
    )
  }

  render = () => {
    let complete = ['model', 'brand', 'location'].reduce((res, key) => 
      res && !!this.state.data[key]
    , true);
    if (!this.props.show) {
      return null;
    }
    return (
      <div style={{backgroundColor: '#f5f5f5'}}>
        <CardTitle
          title='Add Asset'
          subtitle={`S/N: ${this.props.serialNumber}`}
        />
        <div style={{padding: '0px 16px'}}>
          {this.renderPhotoField('photo')}
          {this.renderTextField('model', 'Model')}
          {this.renderTextField('brand', 'Brand')}
          {this.renderTextField('location', 'Location')}
        </div>
        <CardActions style={{textAlign: 'right', padding: '16px 8px 32px'}}>
          <FlatButton
            label='Cancel'
            primary={true}
            onClick={this.onClose}
          />
          <RaisedButton
            label='Add'
            primary={true}
            disabled={!complete}
            onClick={this.onSubmit}
          />
        </CardActions>
      </div>
    )
  }

}

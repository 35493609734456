import React from 'react';

import {Card, CardHeader} from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import {List} from 'material-ui/List';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import PlayIcon from 'material-ui/svg-icons/av/play-arrow';
import CheckIcon from 'material-ui/svg-icons/navigation/check';

import {triggerEvent} from '../../helpers/global.js';
import Storage from '../../helpers/Storage';

const styles = {
  startButton: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    right: 10,
    boxShadow: 'none',
  },
  completeButton: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    right: 10,
    border: '1px solid #57d599',
    display: 'inline-block',
    width: 40,
    height: 40,
    borderRadius: '50%',
    boxSizing: 'border-box',
    textAlign: 'center',
  },
  completeIcon: {
    height: 40,
    color: '#57d599',
  },
};

export default class OrderPartSelectView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      order: props.order,
    }
  }

  render = () => {
    let order = this.state.order;
    let orderComplete = true;
    return (
      <div>
        <List>
          {order.parts.map(part => {
            let checklistsCompleted = (Storage.getData(`checklistsCompleted-${order.id}-${part.id}`) || []).length;
            let complete = checklistsCompleted >= part.assets_count;
            orderComplete = orderComplete && complete;
            return (
              <Card
                key={part.id}
                style={{
                  margin: '0px 12px 12px',
                  borderRadius: 4,
                  boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
                  position: 'relative',
                }}
                onClick={() => this.props.onPartSelect(part)}
              >
                <CardHeader
                title={part.title}
                subtitle={`${checklistsCompleted} / ${part.assets_count}`}
                />
                {complete ?
                  <div style={styles.completeButton}>
                    <CheckIcon style={styles.completeIcon}/>
                  </div>
                  :
                  <FloatingActionButton mini={true} style={styles.startButton}>
                    <PlayIcon/>
                  </FloatingActionButton>
                }
              </Card>
            )
          })}
        </List>
        <div style={{textAlign: 'right', padding: '0px 16px'}}>
          <RaisedButton
            label='Add items'
            onClick={() => {
              triggerEvent('goPage', ['add_asset', order.id]);
            }}
          />
        </div>
        
      </div>
    )
  }

}

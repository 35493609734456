import React from 'react';
import update from 'immutability-helper';

import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';

import {sendRequest, triggerEvent} from '../../helpers/global.js';

const RESEND_TIME = 30;

export default class VerificationCodeView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      inputErrors: {},
      sms_auth: {
        phone: null,
        token: null,
      },
      resendSeconds: 0,
      sendTime: props.sendTime,
    };
    this.timer = null;
  }

  componentDidMount = () => {
    this.timer = setInterval(() => {
      let seconds = Math.max(RESEND_TIME - Math.round((Date.now() - this.state.sendTime) / 1000), 0);
      if (seconds !== this.state.resendSeconds) {
        this.setState({resendSeconds: seconds});
      }
    }, 150);
  }

  componentWillUnmount = () => {
    clearInterval(this.timer);
  }

  componentWillReceiveProps = (props) => {
    if (props.sendTime && props.sendTime !== this.state.sendTime) {
      this.setState({sendTime: props.sendTime});
    }
  }

  onClose = () => {
    this.props.onClose();
  }

  onSubmit = () => {
    let inputErrors = {};

    if (!this.state.data.code) {
      inputErrors.code = 'Please enter code';
    }

    if (Object.keys(inputErrors).length > 0) {
      this.setState({inputErrors});
    } else {
      triggerEvent('addLoad');
      sendRequest({
        type: 'POST',
        method: this.props.method,
        data: {
          sms_otp: this.state.data.code,
          sms_token: this.props.token,
        },
        success: (data)  => {
          triggerEvent('removeLoad');
          this.props.onSuccess(data);
        },
        error: (error) => {
          triggerEvent('removeLoad');
          this.setState(update(this.state, {
            inputErrors: {
              code: {$set: 'Code is invalid'},
            },
            data: {
              code: {$set: ''},
            },
          }));
        }
      })
    }
  }

  onResend = () => {
    triggerEvent('addLoad');
      sendRequest({
        type: 'POST',
        method: 'me/resend_otp',
        data: {
          sms_token: this.props.token,
        },
        success: (data)  => {
          triggerEvent('removeLoad');
          this.props.onResend(data);
        },
        error: (error) => {
          triggerEvent('removeLoad');
        }
      })
  }

  renderTextField = (key, title, type) => {
    return (
      <div key={key} style={{height: 75,}}>
        <TextField
          name={key}
          hintText=''
          floatingLabelText={title}
          floatingLabelFixed={true}
          errorText={this.state.inputErrors[key] || null}
          style={{width: '100%'}}
          value={this.state.data[key] || ''}
          onChange={(e, val) => {
            this.setState(update(this.state, {
              data: {
                [key]: {$set: val},
              },
              inputErrors: {$unset: [key]},
            }))
          }}
        />
      </div>
    )
  }

  render = () => {
    let seconds = this.state.resendSeconds;
    return (
      <Dialog
        title='2 Factor Auth'
        titleStyle={{paddingBottom: 6}}
        contentStyle={{width: 'calc(100% - 40px)', maxWidth: 400}}
        actions={[
          <FlatButton
            label='Cancel'
            primary={true}
            onClick={this.onClose}
          />,
          <RaisedButton
            label='Send'
            primary={true}
            onClick={this.onSubmit}
          />,
        ]}
        modal={false}
        open={this.props.show}
        onRequestClose={this.onClose}
      >
        {`Code was send to ${this.props.phone}`}
        <div style={{textAlign: 'center', padding: '10px 0px 5px'}}>
          <RaisedButton
            label={`Send again${seconds > 0 ? ` (${seconds})` : ''}`}
            disabled={seconds > 0}
            onClick={this.onResend}
          />
        </div>
        {this.renderTextField('code', 'SMS Code', 'text')}
      </Dialog>
    )
  }

}

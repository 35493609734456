import React from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';

import {Card, CardActions, CardTitle, CardHeader} from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import LinearProgress from 'material-ui/LinearProgress';
import Snackbar from 'material-ui/Snackbar';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';

import {triggerEvent, sendRequest, initCodeScan} from '../../helpers/global.js';
import Storage from '../../helpers/Storage';
import SpecifyAssetView from '../SpecifyAssetView';
import OrderInputView from './OrderInputView';
import QuestionTooltip from './QuestionTooltip';
import OrderPartSelectView from './OrderPartSelectView';
import OrderStartView from './OrderStartView';

const ANSWER_OPTIONS = [
  {key: 'pass', title: 'Pass'},
  {key: 'failed', title: 'Fail'},
  {key: 'n_a', title: 'N/A'}
];

const styles = {
  assetName: {
    color: '#333333',
    fontSize: '16px',
    fontWeight: 500,
    padding: '12px 16px 0px',
  },
  labelHeader: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '14px',
    fontWeight: 500,
    padding: '12px 16px 0px',
  },
};

const mapStoreToProps = (store) => {
  return {
    isOnline: store.config.is_online,
  }
};

class OrderView extends React.Component {

  constructor(props) {
    super(props);

    let order = (Storage.getData('orders') || []).find(t => t.id === parseInt(this.props.id));
    this.state = {
      order,
      answers: {},
      started: false,
      currentPart: null,
      currentAsset: null,
      showAlert: false,
      alertMessage: '',
      addingNumber: null,
      showAddAsset: false,
      promptTag: '',
      showPrompt: false,
      showIncompleteWarn: false,
      showConfirmScan: false,
      confirmScan: {
        scan: null,
        part: null,
      },
      showIncorrectAsset: false,
    }
  }

  componentDidMount = () => {
    if (this.state.order) {
      let answers = {};
      this.state.order.parts.forEach(part => {
        Storage.requestAnswerData(`answers-${this.props.id}-${part.id}`, val => {
          answers[part.id] = val || {};
          if (Object.keys(answers).length >= Object.keys(this.state.order.parts).length) {
            this.setState({answers});
          }
        });
      });
    }
  }

  onFinish = () => {
    const order = this.state.order || {};
    if (!order.id) {
      return
    }
    this.sendAssets();
  }

  redirectToSign = () => {
    triggerEvent('goPage', ['sign_order', this.state.order.id]);
  }

  sendAssets = () => {
    triggerEvent('addLoad');
    Storage.requestAnswerData('added_numbers', val => {
      let clientNumbers = val || {};
      if (Object.keys(clientNumbers).length > 0) {
        triggerEvent('addLoad');
        sendRequest({
          type: 'POST',
          method: 'assets/new',
          data: {
            order_id: this.state.order.id,
            assets: clientNumbers,
          },
          success: (data) => {
            Storage.setData('serial_numbers', data);
            Storage.storeAnswerData('added_numbers', null);
            this.sendAnswers();
            triggerEvent('removeLoad');
          },
          error: (error) => {
            triggerEvent('removeLoad');
          }
        });
      } else {
        this.sendAnswers();
      }
      triggerEvent('removeLoad');
    });
  }

  sendAnswers = () => {
    const order = this.state.order || {};
    if (order.status === 'finished') {
      this.redirectToSign();
      return;
    }
    let answersData = {};
    order.parts.forEach(part => {
      answersData[part.id] = {
        asset_class_id: part && part.asset_class_id,
        results: {},
      };
      let checklistsCompleted = Storage.getData(`checklistsCompleted-${this.props.id}-${part.id}`) || [];
      let partAnswers = this.state.answers[part.id] || {};
      Object.keys(partAnswers).filter(assetId => 
        assetId !== 'complete' && checklistsCompleted.indexOf(partAnswers[assetId].serial_number) > -1
      ).forEach(assetId => {
        answersData[part.id].results[assetId] = partAnswers[assetId];
      });
    });

    triggerEvent('addLoad');
    sendRequest({
      type: 'POST',
      method: `orders/${this.props.id}/results`,
      data: {
        data: answersData,
      },
      success: (data) => {
        let orders = Storage.getData('orders') || [];
        let index = orders.findIndex(order => order.id === data.id);
        if (index > -1) {
          orders[index] = data;
          Storage.setData('orders', orders);
        }
        triggerEvent('removeLoad');
        this.redirectToSign();
      },
      error: (error) => {
        triggerEvent('removeLoad');
      }
    });
  }

  onNextClick = () => {
    let order = this.state.order;
    let currentPart = this.state.currentPart;
    let currentAsset = this.state.currentAsset;
    let partAnswers = this.state.answers[currentPart.id];

    let checklistsCompleted = Storage.getData(`checklistsCompleted-${order.id}-${currentPart.id}`) || [];
    if (checklistsCompleted.findIndex(id => `${id}` === `${currentAsset.serial_number}`) < 0) {
      checklistsCompleted.push(currentAsset.serial_number);
      Storage.setData(`checklistsCompleted-${order.id}-${currentPart.id}`, checklistsCompleted);
    }

    if (checklistsCompleted.length >= currentPart.assets_count) {
      partAnswers = update(partAnswers, {
        complete: {$set: true}
      });
      //let nextPart = order.parts.find(part => !this.state.answers[part.id].complete && part.id !== currentPart.id);

      Storage.storeAnswerData(`answers-${this.props.id}-${currentPart.id}`, partAnswers);
      this.setState(update(this.state, {
        answers: {
          [currentPart.id]: {$set: partAnswers}
        },
        currentPart: {$set: null},
        currentAsset: {$set: null},
      }));
    } else {
      this.setState({currentAsset: null});
    }
  }

  updateAnswer = (questionId, key, value) => {
    let currentPart = this.state.currentPart;
    let currentAsset = this.state.currentAsset;
    let partAnswers = this.state.answers[currentPart.id];
    let currentAnswers = partAnswers[currentAsset.id] || {answers: {}};
    let answer = currentAnswers.answers[questionId] || {};
    answer[key] = value;
    currentAnswers.answers[questionId] = answer;
    partAnswers[currentAsset.id] = currentAnswers;
    Storage.storeAnswerData(`answers-${this.props.id}-${currentPart.id}`, partAnswers);

    this.setState({answers:
      update(this.state.answers, {
        [currentPart.id]: {$set: partAnswers}
      })
    });
  }

  onCodeScan = (data) => {
    let order = this.state.order;
    let currentPart = this.state.currentPart;

    triggerEvent('addLoad');
    Storage.requestAnswerData('added_numbers', val => {
      triggerEvent('removeLoad');
      
      let clientNumbers = (val || {})[order.customer_id] || {};
      let serverNumbers = (Storage.getData('serial_numbers') || {})[order.customer_id] || {};
      let foundAsset = serverNumbers[data] || clientNumbers[data] || null;
      if (foundAsset && `${currentPart.asset_class_id}` !== `${foundAsset.asset_class_id}`) {
        this.setState({showIncorrectAsset: true});
        return;
      }
  
      let serialNumberUsed = order.parts.find(part => {
        if (part.id === currentPart.id) {
          return false;
        }
        let partAnswers = this.state.answers[part.id] || {};
        return !!Object.values(partAnswers).find(answer => answer.serial_number === data);
      });
      if (serialNumberUsed) {
        this.setState({confirmScan: {
          scan: data,
          part: serialNumberUsed,
        }}, () => this.setState({showConfirmScan: true}));
        return;
      }
  
      this.processCodeScan(data);
    });
  }

  processCodeScan = (data) => {
    let order = this.state.order;
    let currentPart = this.state.currentPart;

    triggerEvent('addLoad');
    Storage.requestAnswerData('added_numbers', val => {
      triggerEvent('removeLoad');

      let clientNumbers = Object.keys((val || {})[order.customer_id] || {});
      let serverNumbers = Object.keys((Storage.getData('serial_numbers') || {})[order.customer_id] || {});
    
      if (!serverNumbers.concat(clientNumbers).find(code => code === data)) {
        let checklistsCompleted = (Storage.getData(`checklistsCompleted-${order.id}-${currentPart.id}`) || []).length;
        if (checklistsCompleted >= currentPart.assets_count) {
          this.setState({showAlert: true, alertMessage: 'Asset not found'});
        } else {
          this.setState({addingNumber: data, showAddAsset: true});
        }
        return;
      }

      let partAnswers = this.state.answers[currentPart.id];
      let id = Object.keys(partAnswers).find(key =>
        (partAnswers[key] || {})['serial_number'] === data
      );

      let asset = {
        serial_number: data,
        questions: currentPart.questions,
      };
      if (id !== undefined) {
        asset.id = id;
        this.setState({currentAsset: asset});
      } else {
        id = Object.keys(partAnswers).length;
        asset.id = id;

        partAnswers = Object.assign({}, partAnswers);
        partAnswers[id] = {
          serial_number: data,
          answers: {},
        }
        Storage.storeAnswerData(`answers-${this.props.id}-${currentPart.id}`, partAnswers);
        this.setState({
          currentAsset: asset, 
          answers: update(this.state.answers, {[currentPart.id]: {$set: partAnswers}}),
        });
      }
    });
  }

  startOrder = () => {
    const order = this.state.order;
    if (order.started_at) {
      this.setState({started: true});
    } else {
      sendRequest({
        type: 'POST',
        method: `orders/${this.props.id}/start`,
        data: {
          started_at: Date.now(),
        },
        success: (data) => {
          this.setState({
            order: data,
            started: true,
          });
          let orders = Storage.getData('orders') || [];
          let index = orders.findIndex(order => order.id === data.id);
          if (index > -1) {
            orders[index] = data;
            Storage.setData('orders', orders);
          }
          triggerEvent('removeLoad');
        },
        error: (error) => {
          triggerEvent('removeLoad');
        }
      });
    }
  }

  focusQuestion = (question) => {
    let el = document.querySelector(`[data-id='q-${question.id}']`)
    el.scrollIntoView({block: 'center', behavior: 'smooth'});
    el.classList.add('shake-horizontal');
    setTimeout(() => {
      el.classList.remove('shake-horizontal');
    }, 1000);
  }

  renderOptions = (selected, onChange) => {
    return ANSWER_OPTIONS.map(option =>
      <RaisedButton
        key={option.key}
        style={{margin: '10px 0px 16px 16px'}}
        label={option.title}
        primary={option.key === selected}
        onClick={() => onChange(option)}
      />
    );
  }

  renderQuestion = (question, answer) => {
    let value = answer[question.type];
    let onChange = (value, key) => this.updateAnswer(question.id, key || question.type, value);
    let content = null;
    if (question.type === 'checklist') {
      content = <div style={{paddingBottom: 10}}>
        {this.renderOptions(answer.option, (option) => {
          this.updateAnswer(question.id, 'option', option.key);
        })}
        {answer.option === 'failed' ?
          <div style={{padding: '0px 16px'}}>
            <OrderInputView
              type='text'
              value={answer.text}
              onChange={val => onChange(val, 'text')}
              title='Description'
            />
            <OrderInputView
              type='photo'
              single={true}
              value={answer.photo}
              onChange={val => onChange(val, 'photo')}
            />
          </div>
        : null}
      </div>
    } else if (question.type === 'selects') {
      content = <div style={{padding: '0px 16px'}}>
        <OrderInputView
          type={question.type}
          value={value}
          onChange={onChange}
          options={question.answers}
        />
        {value === 0 ?
          <OrderInputView
            type='text'
            val={answer.text}
            onChange={val => onChange(val, 'text')}
            title='Other option'
          />
        : null}
      </div>
    } else if (question.type === 'geo_tag') {
      content = <div style={{padding: '0px 16px'}}>
        <OrderInputView
          type={question.type}
          value={value}
          onChange={onChange}
        />
        <OrderInputView
          type='text'
          value={answer.text}
          onChange={val => onChange(val, 'text')}
          title='Description'
        />
      </div>
    } else {
      content = <div style={{padding: '0px 16px'}}>
        <OrderInputView
          type={question.type}
          value={value}
          onChange={onChange}
        />
      </div>
    }
    return (
      <Card
        key={question.id}
        data-id={`q-${question.id}`}
        style={{
          margin: '0px 12px 16px',
          borderRadius: 4,
          boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
          position: 'relative',
          zIndex: 'initial',
        }}
      >
        <CardHeader title={question.title}/>
        {question.description ? <QuestionTooltip text={question.description}/> : null}
        {content}
      </Card>
    )
  }

  renderNotes = () => {
    let currentPart = this.state.currentPart;
    let currentAsset = this.state.currentAsset;
    let partAnswers = this.state.answers[currentPart.id];
    let currentAnswers = partAnswers[currentAsset.id] || {};
    return (
      <div style={{padding: '8px 16px'}}>
        <OrderInputView
          type='text'
          title='Add asset notes'
          value={currentAnswers['notes'] || ''}
          onChange={val => {
            currentAnswers['notes'] = val;
            partAnswers[currentAsset.id] = currentAnswers;
            Storage.storeAnswerData(`answers-${this.props.id}-${currentPart.id}`, partAnswers);
            this.setState({answers:
              update(this.state.answers, {
                [currentPart.id]: {$set: partAnswers}
              })
            });
          }}
        />
      </div>
    )
  }

  renderProgress = () => {
    let order = this.state.order || {};
    let currentPart = this.state.currentPart;
    let currentAsset = this.state.currentAsset;

    let checklistsCompleted = (Storage.getData(`checklistsCompleted-${order.id}-${currentPart.id}`) || []).length;
    let checklistsCount = currentPart.assets_count;
    let progress = 100.0 * checklistsCompleted / checklistsCount;

    let content = null;
    if (currentAsset) {
      let uncompleteQuestion = null;
      let partAnswers = this.state.answers[currentPart.id];
      let currentAnswers = partAnswers[currentAsset.id] || {answers: {}};
      let lastAsset = checklistsCount - checklistsCompleted <= 1;
      content = <div>
        <div style={styles.assetName}>{currentPart.asset_class_name}</div>
        <div style={styles.labelHeader}>Serial Number:</div>
        <CardHeader
          title={currentAsset.serial_number}
          titleStyle={{fontSize: '20px', lineHeight: '24px', wordBreak: 'break-all'}}
          textStyle={{paddingRight: 0}}
          style={{padding: '4px 16px 32px'}}
        />
        <div>
          {currentAsset.questions.map(question => {
            let answer = currentAnswers.answers[question.id] || {};
            if (!uncompleteQuestion) {
              if (Object.keys(answer).length === 0 && question.type !== 'checkbox') {
                uncompleteQuestion = question;
              }
            }
            return this.renderQuestion(question, answer);
          })}
          {this.renderNotes()}
        </div>
        <CardActions style={{textAlign: 'right', padding: '16px 16px 20px'}}>
          <RaisedButton
            label={lastAsset ? 'Finish' : 'Next'}
            fullWidth={lastAsset}
            style={{marginRight: 0}}
            onClick={() => uncompleteQuestion ? this.focusQuestion(uncompleteQuestion) : this.onNextClick()}
            primary={!uncompleteQuestion}
          />
        </CardActions>
      </div>
    } else if (currentPart) {
      content = <React.Fragment>
        <RaisedButton
          label='Scan Asset Tag'
          primary={true}
          onClick={() => initCodeScan(this.onCodeScan)}
          style={{margin: '0px 16px'}}
        />
        <RaisedButton
          label='Fill in Asset Tag'
          onClick={() => this.setState({showPrompt: true, promptTag: ''})}
        />
      </React.Fragment>
    }
    return (
      <div>
        <CardHeader title={`${checklistsCompleted} / ${checklistsCount}`}/>
        <div style={{padding: 16}}>
          <LinearProgress mode='determinate' value={progress}/>
        </div>
        {content}
      </div>
    )
  }

  render = () => {
    let order = this.state.order || {};
    if (!order.id) {
      return null;
    }
    let orderComplete = order.parts.reduce((res, part) => {
      let checklistsCompleted = (Storage.getData(`checklistsCompleted-${order.id}-${part.id}`) || []).length;
      return res && checklistsCompleted >= part.assets_count;
    }, true);

    if (this.state.showAddAsset) {
      return (
        <SpecifyAssetView
          order={order}
          show={this.state.showAddAsset}
          serialNumber={this.state.addingNumber}
          assetClassId={this.state.currentPart && this.state.currentPart.asset_class_id}
          onClose={() => this.setState({showAddAsset: false})}
          onAdd={() => {
            this.setState({showAddAsset: false}, () => {
              this.onCodeScan(this.state.addingNumber);
            })
          }}
        />
      )
    }
    return (
      <div>
        <div style={{minHeight: 'calc(100vh - 150px)'}}>
          <CardTitle
            title={order.name}
            subtitle={`Work order No. ${order.identifier}`}
            onClick={() => this.setState({currentPart: null, currentAsset: null})}
          />
          {this.state.started ? 
            this.state.currentPart ? this.renderProgress() : 
            <OrderPartSelectView
              order={order}
              onPartSelect={part => this.setState({currentPart: part})}
            />
            :
            <OrderStartView
              order={order}
              onStart={this.startOrder}
            />
          }
        </div>

        {this.state.started && !this.state.currentPart ?
          <div style={{textAlign: 'right', padding: '0px 16px'}}>
            <RaisedButton
              label={this.props.isOnline ? 'Sign' : 'Internet connection required'}
              fullWidth={true}
              disabled={!this.props.isOnline}
              onClick={() => {
                if (orderComplete) {
                  this.onFinish();
                } else {
                  this.setState({showIncompleteWarn: true});
                }
              }}
              style={{margin: '24px 0px'}}
              primary={true}
            />
          </div>
        : null}

        {this.renderResponses()}

      </div>
    );
  }

  renderResponses = () => {
    return <>
      <Snackbar
        open={this.state.showAlert}
        message={this.state.alertMessage}
        autoHideDuration={4000}
        onRequestClose={() => this.setState({showAlert: false})}
      />

      <Dialog
        title='Asset Tag'
        contentStyle={{width: 'calc(100% - 40px)', maxWidth: 400}}
        actions={[
          <FlatButton
            label='Cancel'
            onClick={() => this.setState({showPrompt: false})}
          />,
          <RaisedButton
            label='OK'
            disabled={!this.state.promptTag}
            onClick={() => {
              this.setState({showPrompt: false});
              this.onCodeScan(this.state.promptTag);
            }}
          />,
        ]}
        modal={false}
        open={this.state.showPrompt}
        onRequestClose={() => this.setState({showPrompt: false})}
      >
        <TextField
          fullWidth={true}
          floatingLabelFixed={true}
          floatingLabelText='Enter asset tag'
          value={this.state.promptTag}
          onChange={(e, val) => this.setState({promptTag: val})}
        />
      </Dialog>

      <Dialog
        title='Work order incomplete'
        contentStyle={{width: 'calc(100% - 40px)', maxWidth: 400}}
        actions={[
          <FlatButton
            label='Cancel'
            onClick={() => this.setState({showIncompleteWarn: false})}
          />,
          <RaisedButton
            label='OK'
            onClick={() => {
              this.setState({showIncompleteWarn: false});
              this.onFinish();
            }}
          />,
        ]}
        modal={false}
        open={this.state.showIncompleteWarn}
        onRequestClose={() => this.setState({showIncompleteWarn: false})}
      >
        The work order is incomplete. Are you sure you want to proceed to close the order?
      </Dialog>

      <Dialog
        title='Do you really want to scan this asset?'
        contentStyle={{width: 'calc(100% - 40px)', maxWidth: 400}}
        actions={[
          <FlatButton
            label='Cancel'
            onClick={() => this.setState({showConfirmScan: false})}
          />,
          <RaisedButton
            label='Confirm'
            onClick={() => {
              this.setState({showConfirmScan: false});
              this.processCodeScan(this.state.confirmScan.scan);
            }}
          />,
        ]}
        modal={false}
        open={this.state.showConfirmScan}
        onRequestClose={() => this.setState({showConfirmScan: false})}
      >
        It was scanned in the <b>{(this.state.confirmScan.part || {}).title}</b> scope, 
        result data may be inconsistent
      </Dialog>

      <Dialog
        title='Incorrect asset class'
        contentStyle={{width: 'calc(100% - 40px)', maxWidth: 400}}
        actions={[
          <RaisedButton
            label='OK'
            onClick={() => this.setState({showIncorrectAsset: false})}
          />
        ]}
        modal={false}
        open={this.state.showIncorrectAsset}
        onRequestClose={() => this.setState({showIncorrectAsset: false})}
      >
        Only <b>{(this.state.currentPart || {}).asset_class_name}</b> may be inspected with this checklist.
        Please contact your order dispatcher to clarify.
      </Dialog>
    </>
  }

}

export default connect(mapStoreToProps)(OrderView);

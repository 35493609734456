import {triggerEvent, isIOS, sendRequest} from './global'
import Storage from '../helpers/Storage';

export default class NotificationsController {

  static requestOrders = (handle) => {
    sendRequest({
      type: 'GET',
      method: 'orders',
      success: (data) => {
        Storage.setData('orders', data);
      },
    });
  }
  
  static init() {

    if (window.FirebasePlugin) {
      window.FirebasePlugin.onTokenRefresh((token) => {
        window.FirebasePlugin.grantPermission();
        //alert(token);
        localStorage.setItem('zaiko:device_token', token);
      }, (error) => {});

      window.FirebasePlugin.onNotificationOpen((notification) => {
        if (!localStorage.getItem('zaiko:token')) {
          return;
        }
        this.requestOrders();
        if (isIOS()) {
          notification = notification.aps && notification.aps.alert;
        }
        if (notification.title) {
          triggerEvent('notification', [notification]);
        }
        triggerEvent('updateOrders');
      }, (error) => {});
    }

  }

}

import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';

import {isIOS} from '../helpers/global'

import '../scss/components/UpdateView.scss';

export default class UpdateView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
  }

  onUpdate = () => {
    if (isIOS()) {
      window.open('https://apps.apple.com/us/app/zaiko-building-maintenance/id1460252426', '_blank');
    } else {
      window.open('https://play.google.com/store/apps/details?id=com.zaiko.givola', '_blank');
    }
  }

  render = () => {
    return (
      <div className='updateView'>
        <h2>Version is not supported</h2>
        <p>Please download new version from the store</p>
        <RaisedButton 
          label='Open store'
          primary={true}
          onClick={this.onUpdate}
        />
      </div>
    )
  }

}


import React from 'react';
import update from 'immutability-helper';
import MenuItem from 'material-ui/MenuItem';
import {CardHeader, CardActions} from 'material-ui/Card';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import DatePicker from 'material-ui/DatePicker';
import SelectField from 'material-ui/SelectField';

import {sendRequest, triggerEvent} from '../helpers/global.js';
import Storage from '../helpers/Storage';

export default class AddAssetView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {},
      checklists: Storage.getData('checklists') || [],
      asset_classes: Storage.getData('asset_classes') || [],
      order: (Storage.getData('orders') || []).find(t => t.id === parseInt(this.props.id)),
    }
  }

  componentDidMount = () => {
  }

  onAddItemsPress = () => {
    triggerEvent('addLoad');
    sendRequest({
      type: 'POST',
      method: `orders/${this.props.id}/additional_asset`,
      data: this.state.data,
      success: (data) => {
        triggerEvent('removeLoad');
        let orders = Storage.getData('orders') || [];
        let index = orders.findIndex(order => order.id === data.id);
        if (index > -1) {
          orders[index] = data;
          Storage.setData('orders', orders);
        }
        triggerEvent('goPage', ['order', this.props.id]);
      },
      error: (error) => {
        triggerEvent('removeLoad');
      }
    });
  }

  renderTextField = (key, title, multiline) => {
    return (
      <div style={{padding: '0px 16px'}}>
        <TextField
          multiLine={!!multiline}
          fullWidth={true}
          floatingLabelFixed={true}
          floatingLabelText={title}
          rowsMax={4}
          style={{marginBottom: 16}}
          value={this.state.data[key] || ''}
          onChange={(e, val) => {
            this.setState({data:
              update(this.state.data, {
                [key]: {$set: val}
              })
            });
          }}
        />
      </div>
    )
  }

  renderDateField = (key, title) => {
    let value = this.state.data[key];
    return (
      <div style={{padding: '0px 16px'}}>
        <DatePicker
          floatingLabelText={title}
          floatingLabelFixed={true}
          style={{marginBottom: 16}}
          value={value ? new Date(value) : null}
          onChange={(e, val) => {
            this.setState({data:
              update(this.state.data, {
                [key]: {$set: val.toISOString().substr(0, 10)}
              })
            });
          }}
        />
      </div>
    )
  }

  renderSelectField = (key, title, options, multiple, handle) => {
    return (
      <div style={{padding: '0px 16px'}}>
        <SelectField
          floatingLabelFixed={true}
          floatingLabelText={title}
          style={{marginBottom: 16}}
          value={this.state.data[key] || null}
          fullWidth={true}
          multiple={!!multiple}
          onChange={(e, index, val) => {
            this.setState({data:
              update(this.state.data, {
                [key]: {$set: val}
              })
            }, handle);
          }}
        >
          {options.map(option => 
            <MenuItem key={option.id} value={option.id} primaryText={option.name}/>
          )}
        </SelectField>
      </div>
    )
  }

  render = () => {
    let data = this.state.data;
    let order = this.state.order || {};
    let complete = ['asset_class_id', 'checklist_ids', 'assets_count'].reduce((res, key) => res && !!data[key], true);
    let assetClass = this.state.asset_classes.find(item => item.id === data.asset_class_id);
    let checklists = this.state.checklists.filter(checklist => 
      assetClass && assetClass.checklist_ids.indexOf(checklist.id) > -1
    );

    return (
      <div>
        <CardHeader title={`Add Part for Order #${order.identifier}`} textStyle={{padding: 0}}/>
        
        {this.renderSelectField('asset_class_id', 'Asset class', this.state.asset_classes, false, 
          () => this.setState({data:
            update(this.state.data, {
              checklist_ids: {$set: []}
            })
          })
        )}
        {this.renderSelectField('checklist_ids', 'Checklsits', checklists, true)}
        {this.renderTextField('assets_count', 'Quantity')}

        <CardActions style={{textAlign: 'right', padding: '16px 8px'}}>
          <FlatButton
            label='Cancel'
            onClick={() => triggerEvent('goPage', ['order', this.props.id])}
          />,
          <RaisedButton
            label='Add'
            primary={true}
            disabled={!complete}
            style={{marginLeft: 10}}
            onClick={this.onAddItemsPress}
          />
        </CardActions>
      </div>
    )
  }

}

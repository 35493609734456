import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {CardHeader, CardActions} from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import Checkbox from 'material-ui/Checkbox';

import {sendRequest, triggerEvent} from '../../helpers/global.js';
import Storage from '../../helpers/Storage';

import OrderSummaryView from './OrderSummaryView';

export default class SignOrderView extends React.Component {

  constructor(props) {
    super(props);

    let order = (Storage.getData('orders') || []).find(t => t.id === parseInt(this.props.id));
    this.state = {
      order,
      answers: {},
      remarks: '',
      sendReport: false,
    }
  }

  componentDidMount = () => {
    if (this.state.order) {
      let answers = {};
      this.state.order.parts.forEach(part => {
        Storage.requestAnswerData(`answers-${this.props.id}-${part.id}`, val => {
          answers[part.id] = val || {};
          if (Object.keys(answers).length >= Object.keys(this.state.order.parts).length) {
            this.setState({answers});
          }
        });
      });
    }
  }

  onDecline = () => {
    triggerEvent('addLoad');
    sendRequest({
      type: 'POST',
      method: `orders/${this.props.id}/decline`,
      success: (data) => {
        let orders = Storage.getData('orders') || [];
        let index = orders.findIndex(order => order.id === data.id);
        if (index > -1) {
          orders[index] = data;
          Storage.setData('orders', orders);
        }
        triggerEvent('removeLoad');
        triggerEvent('goPage', ['order', this.state.order.id]);
      },
      error: (error) => {
        triggerEvent('removeLoad');
      }
    });
  }

  onSubmit = () => {
    if (this.customerSign.isEmpty()) {
      triggerEvent('notification', [{title: 'Sign required', body: 'Please ask customer to sign work order results'}]);
      return;
    }
    this.submitSigns();
  }

  submitSigns = () => {
    triggerEvent('addLoad');
    sendRequest({
      type: 'POST',
      method: `orders/${this.props.id}/sign`,
      data: {
        customer_sign: this.customerSign.toDataURL(),
        send_report: this.state.sendReport,
        remarks: this.state.remarks,
      },
      success: (data) => {
        Storage.clearOrderData(this.props.id, () => {
          triggerEvent('notification', [{title: 'Success', body: 'Work order is closed and submitted'}]);
          triggerEvent('goPage', ['orders']);
          triggerEvent('removeLoad');
        });
      },
      error: (error) => {
        triggerEvent('removeLoad');
      }
    });
  }

  renderSignInput = (key, title) => {
    let width = document.body.clientWidth;
    return (
      <div key={key}>
        <CardHeader title={title} textStyle={{padding: 0}}/>
        <div style={{margin: '0px 16px 8px', border: '1px solid #cccccc', backgroundColor: '#ffffff'}}>
          <SignatureCanvas
            penColor='#000000'
            canvasProps={{width: (width-32), height: (width-32)/2, className: 'signatureCanvas'}}
            ref={(ref) => this[key] = ref}
          />
        </div>
        <CardActions style={{padding: '0px 16px 16px'}}>
          <RaisedButton
            label='Clear'
            onClick={() => this[key].clear()}
          />
        </CardActions>
      </div>
    )
  }

  render = () => {
    let order = this.state.order;
    if (!order) {
      return null;
    }
    if (order.status === 'signed') {
      return <OrderSummaryView id={this.props.id} answers={this.state.answers}/>;
    }
    return (
      <div>
        <OrderSummaryView id={this.props.id} answers={this.state.answers}/>
        {order.report_url ?
          <CardActions style={{padding: '24px 16px 8px'}}>
            <FlatButton
              label='View report PDF'
              fullWidth={true}
              primary={true}
              onClick={() => window.open(order.report_url, '_blank')}
            />
          </CardActions>
        : null}
        <div style={{padding: '32px 16px 12px'}}>
          <Checkbox
            label='Send report to Customer'
            checked={this.state.sendReport}
            onCheck={() => this.setState({sendReport: !this.state.sendReport})}
          />
          <TextField
            multiLine={true}
            fullWidth={true}
            floatingLabelFixed={true}
            floatingLabelText='Remarks'
            rowsMax={4}
            style={{marginTop: 8}}
            value={this.state.remarks}
            onChange={(e, val) => this.setState({remarks: val})}
          />
        </div>
        {this.renderSignInput('customerSign', 'Customer Sign')}
        <CardActions style={{display: 'flex', justifyContent: 'space-between', padding: '24px 16px 24px'}}>
          <FlatButton
            label='Decline'
            style={{width: 'calc(50% - 16px)'}}
            onClick={this.onDecline}
          />
          <RaisedButton
            label='Submit'
            style={{width: 'calc(50%)'}}
            onClick={this.onSubmit}
            primary={true}
          />
        </CardActions>
      </div>
    )
  }

}

import reqwest from 'reqwest';

import config from '../config';

export const authUrl = (apiMethod) => {
  return config.json_api_url + '/oauth/' + apiMethod;
}

export const apiUrl = (apiMethod) => {
  return config.json_api_url + '/api/v1/' + apiMethod;
}
 
export const validateEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const getUrlParameter = (sParam, location) => {
  location = location || window.location.search;
  var sPageURL = decodeURIComponent(location.substring(1));
  var sURLVariables = sPageURL.split('&');
  for (let i = 0; i < sURLVariables.length; i++) {
    var sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : sParameterName[1];
    }
  }
  return false;
};

export const isEmpty = (obj) => {
  if (typeof obj === 'undefined' || obj === null) {
    return true;
  } else if (Array.isArray(obj)) {
    return obj.length === 0;
  } else if (obj.constructor === Object) {
    return Object.keys(obj).length === 0;
  } else if (obj.constructor === String) {
    return obj.length === 0;
  } else {
    return !obj;
  }
}

export const getBase64 = (file, handler) => {
  if (!file) {
    if (handler) {
      handler(null);
    }
    return;
  }
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    if (handler) {
      handler(reader.result);
    }
  };
  reader.onerror = function (error) {
    if (handler) {
      handler(null);
    }
  };
}

export const getImageWithCrop = (src, pixelCrop, maxSize, quality, handler) => {
  var format = 'image/jpeg';
  if (src.match('data:image/png;')) {
    format = 'image/png';
  }
  var img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = () => {
    var k = 1;
    var canvas = document.createElement('CANVAS');
    var ctx = canvas.getContext('2d');
    canvas.height = pixelCrop ? pixelCrop.height : img.height;
    canvas.width = pixelCrop ? pixelCrop.width : img.width;
    if (maxSize && maxSize > 0) {
      var k_w = canvas.width <= maxSize ? 1 : maxSize / canvas.width;
      var k_h = canvas.height <= maxSize ? 1 : maxSize / canvas.height;
      k = Math.min(k_w, k_h);
      canvas.width = canvas.width * k;
      canvas.height = canvas.height * k;
    }
    ctx.drawImage(img, 
      pixelCrop ? pixelCrop.x : 0, pixelCrop ? pixelCrop.y : 0, 
      pixelCrop ? pixelCrop.width : img.width, pixelCrop ? pixelCrop.height : img.height, 
      0, 0, canvas.width, canvas.height
    );
    var dataURL = canvas.toDataURL(format, quality);
    if (handler) {
      handler(dataURL);
    }
  };
  img.src = src;
}

export const sendRequest = (properties) => {
  var token = localStorage.getItem('zaiko:token');
  var url = properties.auth ? authUrl(properties.method) : apiUrl(properties.method);
  reqwest({
    url: url,
    method: properties.type,
    data: properties.data,
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    success: (data) => {
      if (properties.success) {
        properties.success(data);
      }
    },
    error: (xhr) => {
      if (xhr.status === 401) {
        triggerEvent('unauthorized', [JSON.parse(xhr.responseText)]);
      }
      console.error(properties.method, xhr.responseText);
      if (properties.error) {
        let response = xhr.response;
        try {
          response = JSON.parse(response);
        } catch (e) {}
        properties.error(response);
      }
    }
  });
}

export const sendDataRequest = (properties) => {
  var token = localStorage.getItem('zaiko:token');
  var url = properties.auth ? authUrl(properties.method) : apiUrl(properties.method);
  var formData = new FormData();
  Object.keys(properties.data).forEach((key) => {
    let item = properties.data[key];
    if (item.constructor === File && item.name) {
      formData.append(key, item, item.name);
    } else {
      formData.append(key, properties.data[key]);
    }
  });
  var request = new XMLHttpRequest();
  request.upload.onprogress = (e) => {
    if (properties.progress) {
      properties.progress(e.loaded / e.total);
    }
  }
  request.onreadystatechange = () => {
    if (request.readyState === 4) {
      if (request.status >= 200 && request.status < 300) {
        if (properties.success) {
          properties.success(JSON.parse(request.response));
        }
      } else {
        if (properties.error) {
          properties.error();
        }
      }
    }
  }
  request.open(properties.type, url);
  request.setRequestHeader('Authorization', 'Bearer ' + token);
  request.send(formData);
}

export const triggerEvent = (name, data) => {
  let event;
  if (window.CustomEvent) {
    event = new CustomEvent(name, {detail: data});
  } else {
    event = document.createEvent('CustomEvent');
    event.initCustomEvent(name, true, true, data);
  }
  document.dispatchEvent(event);
}

export const triggerNativeEvent = (elem, name) => {
  var event = document.createEvent('HTMLEvents');
  event.initEvent(name, true, false);
  elem.dispatchEvent(event);
}

export const triggerClick = (elem) => {
  var event = document.createEvent('MouseEvents');
  event.initMouseEvent('click',true,true,window,0,0,0,0,0,false,false,false,false,0,null);
  elem.dispatchEvent(event);
}

export const eventOn = (name, handler, parent) => {
  parent = parent || document;
  if (handler) {
    parent.addEventListener(name, handler);
  }
}

export const eventOff = (name, handler, parent) => {
  parent = parent || document;
  if (handler) {
    parent.removeEventListener(name, handler);
  }
}

export const isOffline = () => {
  return window.navigator.connection && window.navigator.connection.type === 'none';
}

export const hashCode = (s) => {
  return s.split('').reduce((a,b)=>{
    a=((a<<5)-a)+b.charCodeAt(0);
    return a&a
  },0);
}

const cameraProps = {
  correctOrientation: true,
  saveToPhotoAlbum: false,
  destinationType: 0,
  EncodingType: 0,
  MediaType: 0,
}

export const initCameraPhoto = (handler) => {
  if (window.navigator.camera) {
    window.navigator.camera.getPicture(
      data => handler('data:image/jpeg;base64,'+data),
      () => {},
      cameraProps
    )
  }
}

const barcodeScannerProps = {
  preferFrontCamera: false,
  showTorchButton : true,
  torchOn: false,
  prompt : "Place a barcode inside the scan area",
  resultDisplayDuration: 500,
  disableSuccessBeep: false,
}

export const initCodeScan = (handler) => {
  if (window.cordova) {
    if (window.cordova.plugins && window.cordova.plugins.barcodeScanner) {
      window.cordova.plugins.barcodeScanner.scan(
        (result) => {
          let code = result.text;
          if (code && !code.match(/(https:\/\/)|(http:\/\/)/)) {
            handler(result.text);
          }
        },
        (error) => {},
        barcodeScannerProps,
     );
    }
  } else {
    let code = prompt('Enter barcode', '');
    if (code && !code.match(/(https:\/\/)|(http:\/\/)/)) {
      handler(code);
    }
  }
}

export const isIOS = () => {
  return navigator.userAgent.match(/iPhone/i)
   || navigator.userAgent.match(/iPad/i)
   || navigator.userAgent.match(/iPod/i)
   || false;
}

import React from 'react';
import update from 'immutability-helper';

import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';

import {sendRequest, eventOn, eventOff, triggerEvent} from '../../helpers/global.js';

import VerificationCodeView from './VerificationCodeView';

import '../../scss/components/LoginView.scss';

export default class LoginView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      inputErrors: {},
      sms_auth: {
        show: false,
        phone: null,
        token: null,
        sendTime: null,
      },
      showResetMessage: false,
    };
  }

  componentDidMount = () => {
  }

  componentWillUnmount = () => {
    ['username', 'password'].forEach(key => {
      if (this[key]) {
        eventOff('keydown', this.onKeydown, this[key]);
      }
    })
  }

  onKeydown = (e) => {
    if (e.key === 'Enter') {
      if (e.target === this.username) {
        this.password.focus();
      } else if (e.target === this.password) {
        this.onSubmit();
      }
    }
  }

  onLoginError = () => {
    this.password.focus();
    this.setState(update(this.state, {
      inputErrors: {
        password: {$set: 'Credentials are invalid'},
      },
      data: {
        password: {$set: ''},
      },
    }));
  }

  onSubmit = (e) => {
    if (e) e.preventDefault();

    let username = this.state.data.username || '';
    let password = this.state.data.password || '';
    let inputErrors = {};

    if (username.length === 0) {
      inputErrors.username = 'Please enter username';
    }
    if (password.length === 0) {
      inputErrors.password = 'Please enter password';
    }

    if (Object.keys(inputErrors).length > 0) {
      this.setState({inputErrors});
    } else {
      triggerEvent('addLoad');
      sendRequest({
        type: 'POST',
        method: 'auth/login',
        data: {
          username,
          password,
          device_token: localStorage.getItem('zaiko:device_token'),
        },
        success: (data)  => {
          triggerEvent('removeLoad');
          if (data.user) {
            triggerEvent('login', [data]);
          } else {
            this.setState({
              sms_auth: {
                show: true,
                phone: data.phone,
                token: data.sms_token,
                sendTime: Date.now(),
              },
            });
          }
        },
        error: (error) => {
          triggerEvent('removeLoad');
          this.onLoginError();
        }
      })
    }
  }

  renderTextField = (key, title, type) => {
    return (
      <div key={key} style={{height: 75,}}>
        <TextField
          name={key}
          hintText=''
          floatingLabelText={title}
          floatingLabelFixed={true}
          errorText={this.state.inputErrors[key] || null}
          style={{width: '100%'}}
          type={type}
          autoComplete={key}
          ref={(textField) => {
            if (textField) {
              this[key] = textField.input;
              eventOff('keydown', this.onKeydown, textField.input);
              eventOn('keydown', this.onKeydown, textField.input);
            }
          }} 
          value={this.state.data[key] || ''}
          onChange={(e, val) => {
            this.setState(update(this.state, {
              data: {
                [key]: {$set: val},
              },
              inputErrors: {$unset: [key]},
            }))
          }}
        />
      </div>
    )
  }

  renderLoginForm = () => {
    return (
      <div>
        <h1>Login</h1>
        <form onSubmit={this.onSubmit}>
          {this.renderTextField('username', 'Username', 'text')}
          {this.renderTextField('password', 'Password', 'password')}
          <div className='buttonContainer'>
            <FlatButton 
              style={{float: 'left'}}
              labelStyle={{padding: 0}}
              label='Forgot pasword'
              primary={true}
              onClick={() => this.setState({showResetMessage: true})}
            />
            <RaisedButton 
              label='Login'
              primary={true}
              onClick={this.onSubmit}
            />
          </div>
        </form>
      </div>
    )
  }

  render = () => {
    let sms_auth = this.state.sms_auth;
    return (
      <div className='loginView'>
        {this.renderLoginForm()}
        <VerificationCodeView
          show={sms_auth.show}
          token={sms_auth.token}
          phone={sms_auth.phone}
          sendTime={sms_auth.sendTime}
          method='auth/sms_login'
          onSuccess={(data) => {
            this.setState({sms_auth: {...sms_auth, show: false}});
            triggerEvent('login', [data]);
          }}
          onResend={(data) => {
            this.setState({sms_auth: {...sms_auth, sendTime: Date.now()}})
          }}
          onClose={() => this.setState({sms_auth: {...sms_auth, show: false}})}
        />
        <Dialog
          title='Reset password'
          contentStyle={{width: 'calc(100% - 40px)', maxWidth: 400}}
          actions={[
            <RaisedButton
              label='OK'
              onClick={() => this.setState({showResetMessage: false})}
            />,
          ]}
          modal={false}
          open={this.state.showResetMessage}
          onRequestClose={() => this.setState({showResetMessage: false})}
        >
          Please contact your Supervisor for restoring your password
        </Dialog>
      </div>
    )
  }

}

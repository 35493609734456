var environtment = {
  production: {
    json_api_url: 'https://api.zaiko.co'
    //json_api_url: 'https://zaiko-backend.herokuapp.com'
  },
  staging: {
    json_api_url: 'https://zaiko-backend.herokuapp.com'
  },
  local: {
    //json_api_url: 'http://7d83d124.ngrok.io'
    json_api_url: 'https://zaiko-backend.herokuapp.com'
  }
}

var config = {
  json_api_url : environtment.production.json_api_url,
  environment: 'production',
  version: 1,
}
// Local environment
if (window.location.hostname.match(/([^.]+\.ngrok\.io)|(^localhost)/)) {
  config.json_api_url = environtment.local.json_api_url;
  config.environment = 'local';
}
// Staging environment
if (window.location.hostname.match('herokuapp.com')) {
  config.json_api_url = environtment.staging.json_api_url;
  config.environment = 'staging';
}
// Production environment
if (window.location.hostname === 'm.zaiko.co') {
  config.json_api_url = environtment.production.json_api_url;
  config.environment = 'production';
}

export default config;

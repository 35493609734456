import React from 'react';
import reactHtmlId from 'react-html-id';

import {CardHeader} from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import DatePicker from 'material-ui/DatePicker';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Checkbox from 'material-ui/Checkbox';
import Paper from 'material-ui/Paper';
import CloseIcon from 'material-ui/svg-icons/navigation/close';

import {getBase64, getImageWithCrop, initCodeScan, eventOn, eventOff, initCameraPhoto} from '../../helpers/global.js';

export default class OrderInputView extends React.Component {

  constructor(props) {
    super(props);
    reactHtmlId.enableUniqueIds(this);
    this.state = {
    }
    this.id = this.nextUniqueId();
    this.mapDiv = null;
    this.map = null;
    this.scrollTimeout = null;
  }

  componentDidMount = () => {
    if (this.props.type === 'geo_tag' && window.cordova) {
      eventOn('scroll', this.updateMap, window);
    }
  }

  componentWillUnmount = () => {
    if (this.props.type === 'geo_tag' && window.cordova) {
      eventOff('scroll', this.updateMap, window);
      if (this.map) {
        this.map.remove();
      }
    }
  }

  onFocus = (e) => {
    let el = e.currentTarget;
    setTimeout(() => {
      el.scrollIntoView({block: 'center', behavior: 'smooth'});
    }, 100);
  }

  updateMap = () => {
    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      if (this.map && this.mapDiv) {
        this.map.setDiv(this.mapDiv);
      }
    }, 150);
  }

  initMap = (div, value, onChange) => {
    if (!this.map && div) {
      let maps = window.plugin && window.plugin.google && window.plugin.google.maps;
      if (maps) {
        this.map = maps.Map.getMap(div, {
          controls: {
            myLocationButton: true,
            myLocation: true
          }
        });
        this.mapDiv = div;
        this.map.on(maps.event.MAP_READY, () => {
          if (value.lat) {
            let location = {
              lat: parseFloat(value.lat),
              lng: parseFloat(value.lng),
            };
            this.map.moveCamera({
              target: location,
              zoom: 16
            });
            this.map.addMarker({
              position: location,
              title: 'Location',
            });  
          } else {
            this.map.getMyLocation(location => {
              this.map.moveCamera({
                target: location.latLng,
                zoom: 16
              });
            });
          }
        });
        this.map.on(maps.event.MAP_CLICK, latLng => {
          this.map.clear();
          this.map.addMarker({
            position: latLng,
            title: 'Location',
          });
          onChange({
            lat: latLng.lat,
            lng: latLng.lng,
          });
        });
      }
    }
    if (this.map && div && div !== this.mapDiv) {
      this.map.setDiv(this.mapDiv);
    }
  }

  renderGeoTag = (value = {}, onChange) => {
    return (
      <div style={{paddingBottom: 10}}>
        {window.cordova ?
          <div
            style={{
              width: '100%',
              height: 320,
              border: '1px solid #999999',
              position: 'relative',
            }}
            ref={div => this.initMap(div, value, onChange)}
          />
        : null}
        {['lat', 'lng'].map((key, index) =>
          <div key={key} style={{
            width: 'calc(50% - 10px)',
            display: 'inline-block',
            marginLeft: index % 2 ? 20 : 0,
          }}>
            <TextField
              multiLine={true}
              fullWidth={true}
              floatingLabelFixed={true}
              floatingLabelText={key === 'lat' ? 'Lat' : 'Lng'}
              rowsMax={4}
              style={{marginBottom: 16}}
              value={value[key] || ''}
              onChange={(e, val) => {
                let parsed = (val).toString();
                onChange({...value, [key]: parsed})
              }}
              onFocus={this.onFocus}
            />
          </div>
        )}
      </div>
    )
  }

  renderCode = (value, onChange) => {
    return (
      <div style={{paddingBottom: 10}}>
        {value ? 
          <CardHeader
            title={value}
            titleStyle={{wordBreak: 'break-all'}}
            textStyle={{paddingRight: 0}}
            style={{padding: '0px 0px 16px'}}
          />
        : null}
        <RaisedButton
          label={value ? 'Scan again' : 'Scan Code'}
          onClick={() => initCodeScan(onChange)}
        />
      </div>
    )
  }

  renderText = (value, onChange, title) => {
    return (
      <TextField
        multiLine={true}
        fullWidth={true}
        floatingLabelFixed={true}
        floatingLabelText={title}
        rowsMax={4}
        style={{marginBottom: 16}}
        value={value}
        onChange={(e, val) => onChange(val)}
        onFocus={this.onFocus}
      />
    )
  }

  renderNumber = (value, onChange, title) => {
    return (
      <TextField
        multiLine={true}
        fullWidth={true}
        floatingLabelFixed={true}
        floatingLabelText={title}
        rowsMax={4}
        style={{marginBottom: 16}}
        value={value}
        onChange={(e, val) => onChange(val.replace(/[\D]+/g, ''))}
        onFocus={this.onFocus}
      />
    )
  }

  renderCheckbox = (value, onChange) => {
    return (
      <Checkbox
        checked={value}
        onCheck={() => onChange(!value)}
        style={{paddingBottom: 20}}
      />
    )
  }

  renderDate = (value, onChange) => {
    return (
      <DatePicker
        floatingLabelText={'Select date'}
        floatingLabelFixed={true}
        style={{paddingBottom: 16}}
        value={value ? new Date(value) : null}
        onChange={(e, val) => onChange(val.toISOString().substr(0, 10))}
      />
    )
  }

  renderSelect = (value, onChange, options) => {
    return (
      <SelectField
        floatingLabelText='Select option'
        style={{marginBottom: 16}}
        value={value}
        fullWidth={true}
        onChange={(e, index, val) => onChange(val)}
      >
        {(options || []).map(item => 
          <MenuItem key={item.id} value={item.id} primaryText={item.title}/>
        )}
        <MenuItem value={0} primaryText='Other'/>
      </SelectField>
    )
  }

  renderPhoto = (value, onChange) => {
    let single = !!this.props.single;
    let inputKey = `image-${this.id}`;
    const onUpload = (base64) => {
      if (single) {
        let val = (value || [])[0] || {};
        onChange([{text: val.text || '', image: base64}]);
      } else {
        onChange((value || []).concat([{text: '', image: base64}]));
      }
    }
    const onValueChange = (index, key, val) => {
      let object = value.slice();
      object[index][key] = val;
      onChange(object);
    }
    const onDelete = (index) => {
      let val = value.slice();
      val.splice(index, 1);
      onChange(val);
    }
    return (
      <div style={{paddingBottom: 16}}>
        {(value || []).map((item, index) => 
          <Paper
            key={index}
            zDepth={1}
            style={{fontSize: 0, marginBottom: 15, position: 'relative'}}
          >
            {single ? null :
              <div onClick={() => onDelete(index)} style={{
                position: 'absolute',
                top: 0,
                right: 0,
                padding: 4,
                cursor: 'pointer',
                backgroundColor: 'rgba(125,125,125,0.3)',
              }}>
                <CloseIcon style={{color: '#666666'}}/>
              </div>
            }
            <img src={item.image} style={{maxWidth: '100%'}} alt=''/>
            {single ? null :
              <div style={{padding: '0px 10px'}}>
                <TextField
                  multiLine={true}
                  fullWidth={true}
                  floatingLabelFixed={true}
                  floatingLabelText='Description'
                  rowsMax={4}
                  value={item.text}
                  onChange={(e, val) => onValueChange(index, 'text', val)}
                />
              </div>
            }
          </Paper>
        )}
        <input 
          type='file'
          onChange={(e) => {
            if (e.target.files && e.target.files.length > 0) {
              let file = e.target.files[0];
              getBase64(file, (base64) => 
                getImageWithCrop(base64, null, 768, 0.6, onUpload)
              );
            }
          }}
          ref={input => this[inputKey] = input}
          accept='image/*'
          style={{display: 'none'}}
        />
        <RaisedButton
          label={single ?
            (value && value[0]) ? 'Change photo' : 'Upload photo'
          : 'Upload photo'}
          onClick={() => {
            if (window.cordova) {
              initCameraPhoto(base64 => 
                getImageWithCrop(base64, null, 768, 0.6, onUpload)
              );
            } else {
              this[inputKey].click();
            }
          }}
        />
      </div>
    )
  }

  render = () => {
    const type = this.props.type;
    const value = this.props.value;
    const onChange = this.props.onChange;
    const title = this.props.title;
    if (type === 'text') {
      return this.renderText(value, onChange, title || 'Answer')
    } else if (type === 'date') {
      return this.renderDate(value, onChange);
    } else if (type === 'number') {
      return this.renderNumber(value, onChange, title || 'Number');
    } else if (type === 'checkbox') {
      return this.renderCheckbox(value, onChange);
    } else if (type === 'code') {
      return this.renderCode(value, onChange);
    } else if (type === 'photo') {
      return this.renderPhoto(value, onChange);
    } else if (type === 'geo_tag') {
      return this.renderGeoTag(value, onChange);
    } else if (type === 'selects') {
      const options = this.props.options;
      return this.renderSelect(value, onChange, options);
    } else {
      return null;
    }
  }

}

import { createStore, combineReducers } from 'redux'
import localForage from "localforage";

var dataStateReducer = (state = {}, action) => {
  switch (action.type.toLowerCase()) {
    case 'data':
      return Object.assign({}, state, {
        [action.key]: action.value
      })
    default:
      return state
  }
}

var configStateReducer = (state = {}, action) => {
  switch (action.type.toLowerCase()) {
    case 'config':
      return Object.assign({}, state, {
        [action.key]: action.value
      })
    default:
      return state
  }
}

const stateReducer = combineReducers({
  data: dataStateReducer,
  config: configStateReducer,
})

let storeInstance = null;

export default class Storage {

  static getStore() {
    if(!storeInstance){
      storeInstance = createStore(stateReducer);
    }
    return storeInstance;
  }
  
  // Config
  static setConfig(key, value) {
    this.getStore().dispatch({
      type: 'config', 
      key: key, 
      value: value
    });
  }

  static getConfig(key) {
    return this.getStore().getState()['config'][key];
  }

  // Data
  static setData(key, data) {
    if (data) {
      localStorage.setItem(`zaiko:data:${key}`, JSON.stringify(data));
    } else {
      localStorage.removeItem(`zaiko:data:${key}`);
    }
    this.getStore().dispatch({
      type: 'data',
      key: key,
      value: data,
    });
  }

  static getData(key) {
    let result = this.getStore().getState()['data'][key];
    if (!result) {
      result = JSON.parse(localStorage.getItem(`zaiko:data:${key}`));
    }
    return result;
  }

  //Answers Data
  static storeAnswerData(key, value, callback) {
    localForage.setItem(key, value, (err, val) => {
      if (callback) {
        callback(!err);
      }
    });
  }
  
  static requestAnswerData(key, callback) {
    localForage.getItem(key, (err, val) => {
      if (callback) {
        callback(val || null);
      }
    });
  }

  static clearAllData(callback) {
    localForage.clear(err => {
      Object.keys(localStorage).forEach(key => {
        if (key.match('^zaiko:data:((checklistsCompleted-))')) {
          this.setData(key.replace("zaiko:data:", ""), null);
        }
      });
      if (callback) {
        callback();
      }
    });
  }

  static clearOrderData(orderId, callback) {
    localForage.keys((err, keys) => {
      keys.forEach(key => {
        if (key.match(`^answers-${orderId}`)) {
          localForage.removeItem(key);
        }
      });
      Object.keys(localStorage).forEach(key => {
        if (key.match(`^zaiko:data:((checklistsCompleted-${orderId}))`)) {
          this.setData(key.replace("zaiko:data:", ""), null);
        }
      });
      if (callback) {
        callback();
      }
    });
  }

}

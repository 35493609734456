import React from 'react';
import { connect } from 'react-redux';
import AppBar from 'material-ui/AppBar';
import Drawer from 'material-ui/Drawer';
import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';
import IconButton from 'material-ui/IconButton';
import MenuIcon from 'material-ui/svg-icons/navigation/menu';
import RefreshIcon from 'material-ui/svg-icons/navigation/refresh';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';

import {triggerEvent, initCodeScan, isOffline} from '../helpers/global.js';

const mapStoreToProps = (store) => {
  return {
    isOnline: store.config.is_online,
  }
};

class HeaderView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showPrompt: false,
    }
  }

  componentDidMount = () => {
  }

  onScanClick = () => {
    if (isOffline()) {
      triggerEvent('notification', [{title: 'No connectivity', body: 'This function requires internet connection'}]);
    } else {
      initCodeScan(code => triggerEvent('goPage', ['scan_info', code]));
    }
  }

  renderCameraButton = () => {
    if (!this.props.isOnline) {
      return null;
    }
    return (
      <IconButton onClick={this.onScanClick} iconStyle={{fill: '#ffffff'}}>
        <svg
          version='1.0'
          xmlns='http://www.w3.org/2000/svg'
          x='0px' 
          y='0px' 
          viewBox='0 0 480 480'
        >
          <g>
            <path d="M80,48H16C7.168,48,0,55.168,0,64v64c0,8.832,7.168,16,16,16c8.832,0,16-7.168,
              16-16V80h48c8.832,0,16-7.168,16-16C96,55.168,88.832,48,80,48z"/>
            <path d="M464,336c-8.832,0-16,7.168-16,16v48h-48c-8.832,0-16,7.168-16,16c0,8.832,7.168,
              16,16,16h64c8.832,0,16-7.168,16-16v-64C480,343.168,472.832,336,464,336z"/>
            <path d="M464,48h-64c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h48v48c0,8.832,7.168,
              16,16,16c8.832,0,16-7.168,16-16V64C480,55.168,472.832,48,464,48z"/>
            <path d="M80,400H32v-48c0-8.832-7.168-16-16-16c-8.832,0-16,7.168-16,16v64c0,8.832,7.168,
              16,16,16h64c8.832,0,16-7.168,16-16C96,407.168,88.832,400,80,400z"/>
          </g>
          <g>
            <rect x="64" y="112" width="32" height="256"/>
            <rect x="128" y="112" width="32" height="192"/>
            <rect x="192" y="112" width="32" height="192"/>
            <rect x="256" y="112" width="32" height="256"/>
            <rect x="320" y="112" width="32" height="192"/>
            <rect x="384" y="112" width="32" height="256"/>
            <rect x="128" y="336" width="32" height="32"/>
            <rect x="192" y="336" width="32" height="32"/>
            <rect x="320" y="336" width="32" height="32"/>
          </g>
        </svg>
      </IconButton>
    )
  }

  render = () => {
    let hideControlls = this.props.hideControlls;
    let topOffset = Math.min(Math.max(64-window.scrollY, 0), 64);
    let rightButton = null;
    if (this.props.page === 'orders' && !hideControlls) {
      rightButton = <>
        {this.renderCameraButton()}
        <IconButton onClick={() => triggerEvent('refreshOrders')}>
          <RefreshIcon color='#ffffff'/>
        </IconButton>
      </>
    }
    return (
      <div>

        <AppBar
          title={null}
          showMenuIconButton={!!this.props.logged && !hideControlls}
          onLeftIconButtonClick={() => this.props.onMenuSwitch(!this.props.menuOpen)}
          iconElementLeft={hideControlls ? null : <IconButton><MenuIcon color='#ffffff'/></IconButton>}
          iconElementRight={rightButton}
          iconStyleLeft={{color: '#ffffff'}}
          iconStyleRight={{marginLeft: 8}}
          style={{
            backgroundColor: '#333333',
            backgroundImage: 'url(./logo.png)',
            backgroundSize: '130px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />

        <Drawer
          docked={false}
          open={this.props.menuOpen}
          overlayStyle={{top: topOffset}}
          containerStyle={{
            top: topOffset,
            boxShadow: `40px 0 80px 0 rgba(0, 0, 0, ${this.props.menuOpen ? 0.1 : 0.0})`,
            paddingTop: 1, 
            width: 240,
            height: '100%',
            backgroundColor: '#f5f5f5',
          }}
          onRequestChange={(open) => this.props.onMenuSwitch(open)}
        >
          <MenuItem onClick={() => triggerEvent('goPage', ['orders'])}>Orders</MenuItem>
          <Divider/>
          <MenuItem onClick={() => triggerEvent('goPage', ['settings'])}>Settings</MenuItem>
          <Divider/>
          <MenuItem onClick={() => this.setState({showPrompt: true})}>Logout</MenuItem>
        </Drawer>

        <Dialog
          title='Logout'
          contentStyle={{width: 'calc(100% - 40px)', maxWidth: 400}}
          actions={[
            <FlatButton
              label='Cancel'
              onClick={() => this.setState({showPrompt: false})}
            />,
            <RaisedButton
              label='Logout'
              onClick={() => {
                this.setState({showPrompt: false});
                triggerEvent('logout')
              }}
            />,
          ]}
          modal={false}
          open={this.state.showPrompt}
          onRequestClose={() => this.setState({showPrompt: false})}
        >
          Are you sure?
        </Dialog>

      </div>
    );
  }

}

export default connect(mapStoreToProps)(HeaderView);

import React from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';

import {sendRequest, eventOn, triggerEvent, isOffline} from './helpers/global';
import Storage from './helpers/Storage';
import config from './config';

import LoadIndicator from './components/LoadIndicator';
import HeaderView from './components/HeaderView';
import LoginView from './components/LoginView/LoginView';
import OrderListView from './components/OrderListView';
import OrderView from './components/OrderView/OrderView';
import SignOrderView from './components/SignOrderView/SignOrderView';
import ScanInfoView from './components/ScanInfoView';
import AddAssetView from './components/AddAssetView';
import SettingsView from './components/SettingsView';
import UpdateView from './components/UpdateView';

const mapStoreToProps = (store) => {
  return {
    user: store.data.user,
  }
};

class Zaiko extends React.Component {

  constructor(props) {
    super(props);
    this.loadCount = 0;

    let user = Storage.getData('user') || {};
    let page = 'login';
    let subpage = null;
    let point = this.getInitialPoint(user);
    if (localStorage.getItem('zaiko:token')) {
      if (window.cordova) {
        page = point.page;
        subpage = point.subpage;
      } else {
        let parts = document.location.pathname.substr(1).split('/');
        if (parts[0]) {
          page = parts[0];
          subpage = parts[1] || null;
        } else {
          page = point.page;
          subpage = point.subpage;
        }
      }
    }
    this.state = {
      isMobile: window.innerWidth <= 768,
      menuOpen: false,
      page: page,
      subpage: subpage,
      token: localStorage.getItem('zaiko:token'),
      showLoadIndicator: false,
      user,
      title: '',
      showNotification: false,
      notification: null,
      versionNotSupported: false,
    };
    this.onlineTimer = null;

    eventOn('addLoad', () => {
      this.addLoad();
    });
    eventOn('removeLoad', () => {
      this.removeLoad();
    });
    eventOn('login', (e) => {
      this.onLogin(e.detail[0], e.detail[1] || null);
    });
    eventOn('logout', (e) => {
      this.onLogout();
    });
    eventOn('goPage', (e) => {
      this.goPage(e.detail[0], e.detail[1] || null);
    });
    eventOn('openLink', (e) => {
      window.open(e.detail[0], '_blank');
    });
    eventOn('notification', (e) => {
      this.setState({showNotification: true, notification: e.detail[0]});
    });
    eventOn('updateOrders', () => {
      this.requestUserData('orders', 'orders', null, true);
    });
    eventOn('unauthorized', (e) => {
      this.onUnauthorized(e.detail[0]);  
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  componentWillReceiveProps = (props) => {
    if (props.user) {
      this.setState({user: props.user});
    }
  }

  componentDidMount = () => {
    this.checkVersion();

    Storage.setConfig('is_mobile', window.innerWidth <= 768);
    eventOn('resize', (e) => {
      this.setState({isMobile: window.innerWidth <= 768});
      Storage.setConfig('is_mobile', window.innerWidth <= 768);
    }, window);

    Storage.setConfig('is_online', !isOffline());
    this.onlineTimer = setInterval(() => {
      if (Storage.getConfig('is_online') !== !isOffline()) {
        Storage.setConfig('is_online', !isOffline());
      }
    }, 3000);

    window.onpopstate = (event) => {
      let page = null;
      let subpage = null;
      let parts = document.location.pathname.substr(1).split('/');
      let point = this.getInitialPoint(Storage.getData('user') || {});
      if (window.cordova && parts.length > 2) {
        page = point.page;
        subpage = point.subpage;
      } else {
        if (parts[0]) {
          page = parts[0];
          subpage = parts[1] || null;
        } else {
          page = point.page;
          subpage = point.subpage;
        }
      }
      this.setState({page, subpage, menuOpen: false});
      if (!window.cordova) {
        window.history.replaceState({}, '', `/${page !== 'main' ? page : ''}${subpage ? '/' + subpage : ''}`);
      }
      triggerEvent('pageChanged', [page, subpage]);
    };

    if (this.state.token) {
      this.requestUserData('me', 'user', (user) => {
        this.updateUserData(user);
      });
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.onlineTimer);
  }

  checkVersion = () => {
    this.addLoad()
    sendRequest({
      type: 'GET',
      method: 'version/actual',
      data: {current: config.version},
      success: (data) => {
        this.setState({versionNotSupported: !data}, () => {
          this.removeLoad();
        });
      },
      error: (error) => {
        this.removeLoad();
      }
    });
  }

  onLogin = (data, type) => {
    localStorage.setItem('zaiko:token', data.access_token);
    Storage.setData('user', data.user);
    this.setState({
      token: data.access_token,
      user: data.user,
    });
    this.updateUserData(data.user);
    let point = this.getInitialPoint(data.user);
    this.goPage(point.page, point.subpage);
  }

  getInitialPoint = (user) => {
    user = user || Storage.getData('user');
    if (user.sign_url) {
      return {page: 'orders', subpage: null};
    } else {
      return {page: 'settings', subpage: null};
    }
  }

  updateUserData = (user) => {
    this.requestUserData('assets/serial_numbers', 'serial_numbers');
    this.requestUserData('orders/asset_classes', 'asset_classes');
    this.requestUserData('orders/checklists', 'checklists');
  }

  addLoad = () => {
    this.loadCount++;
    this.setState({showLoadIndicator: this.loadCount > 0});
  }

  removeLoad = () => {
    this.loadCount--;
    this.setState({showLoadIndicator: this.loadCount > 0});
  }

  requestUserData = (request, key, handler, disableLoad) => {
    if (this.state.token) {
      if (!disableLoad) this.addLoad();
      sendRequest({
        type: 'GET',
        method: request,
        success: (data) => {
          Storage.setData(key, data);
          if (handler) {
            handler(data);
          }
          if (!disableLoad) this.removeLoad();
        },
        error: (error) => {
          if (!disableLoad) this.removeLoad();
        }
      });
    }
  }

  goPage = (page, subpage, token) => {
    subpage = subpage || null;
    if (!window.cordova) {
      window.history.pushState({}, '', `/${page !== 'main' ? page : ''}${subpage ? '/' + subpage : ''}`);
    }
    this.setState({
      page: page,
      subpage: subpage,
      menuOpen: false,
      token: typeof token !== 'undefined' ? token : this.state.token,
      title: '',
    });
  }

  onLogout = () => {
    sendRequest({
      type: 'POST',
      method: 'me/logout',
      data: {
        device_token: localStorage.getItem('zaiko:device_token'),
      },
      success: (data) => {
      },
      error: (error) => {
      }
    });
    Storage.setData('user', null);
    localStorage.removeItem('zaiko:token');
    this.goPage('login', null, null);
  }

  onUnauthorized = (data) => {
    if (data && data.type === 'trial') {
      this.setState({
        notification: {
          title: 'Trial expired',
          body: 'Your trial period has been expired. Please contact administrator.',
        },
        showNotification: true,
      });
    } else {
      this.setState({
        notification: {
          title: 'Token expired',
          body: 'Your access token has been expired. Please re-login.',
        },
        showNotification: true,
      });
    }
    Storage.setData('user', null);
    localStorage.removeItem('zaiko:token');
    this.goPage('login', null, null);
  }

  renderCurrentView = () => {
    if (this.state.versionNotSupported) {
      return <UpdateView/>
    }

    switch (this.state.page) {
      case 'login':
        return <LoginView/>
      case 'orders':
        return <OrderListView/>
      case 'order':
        return <OrderView id={this.state.subpage}/>
      case 'sign_order':
        return <SignOrderView id={this.state.subpage}/>
      case 'scan_info':
        return <ScanInfoView code={this.state.subpage}/>
      case 'add_asset':
        return <AddAssetView id={this.state.subpage}/>
      case 'settings':
        return <SettingsView/>
      default:
        return null;
      
    }
  }

  renderConent = () => {
    return (
      <div className='animated' style={{
        paddingLeft: !this.state.isMobile && this.state.menuOpen ? 240 : 0,
        minHeight: 'calc(100vh - 64px)',
        backgroundColor: '#f5f5f5',
      }}>
        <ReactCSSTransitionGroup
          transitionName='right'
          transitionEnterTimeout={450}
          transitionLeaveTimeout={450}
        >
          <div key={`${this.state.page}/${this.state.subpage || ''}`} style={{width: '100%'}}>
            {this.renderCurrentView()}
          </div>
        </ReactCSSTransitionGroup>
      </div>
    )
  }

  render = () => {
    let notification = this.state.notification;
    return (
      <div className='app'>

        <LoadIndicator show={this.state.showLoadIndicator}/>
        <HeaderView
          logged={!!this.state.token}
          page={this.state.page}
          subpage={this.state.subpage}
          menuOpen={this.state.menuOpen}
          onMenuSwitch={menuOpen => this.setState({menuOpen})}
          hideControlls={this.state.versionNotSupported}
        />
        
        {this.renderConent()}

        {notification ? 
          <Dialog
            title={notification.title}
            actions={[
              <RaisedButton
                label='Ok'
                onClick={() => this.setState({showNotification: false})}
              />
            ]}
            modal={false}
            open={this.state.showNotification}
            onRequestClose={() => this.setState({showNotification: false})}
          >
            {notification.body}
          </Dialog>
        : null}

      </div>
    )
  }
}

export default connect(mapStoreToProps)(Zaiko);
